import React, { useState, useEffect } from 'react';
import { composePaths } from '@jsonforms/core';
import { ResolvedJsonFormsDispatch, withJsonFormsArrayLayoutProps } from '@jsonforms/react';
import '../assets/slds/styles/salesforce-lightning-design-system.min.css'
import map from 'lodash/map';
import range from 'lodash/range';
import CQSectionHeader from '../components/CQSectionHeader';



const CQSection = (props) => {

  // const { uischema, schema, path, visible, renderers } = props;

  const [isToggle, setIsToggle] = useState<Boolean[]>([]);



  const { uischema, schema, path, data, renderers, enabled } = props;
  const childUISchema = uischema.layout;
  
  /**
   * This method return schema for guide
   * @param childSchema 
   * @param path 
   * @returns guide schema 
   */
  const getGuideSchema = (childSchema, path) => {
    let guideSchema : any = { 'type' : 'guide', 'scope' : path, 'options' : childSchema.options.guides }
    return guideSchema;
  }

  /**
   * This method set initial values of isToggle state
   */
  const initialize = () => {
    console.log('Initialize')
    let copy : Boolean[] = []
    for (let i = 0; i < data; i++) {
        copy[i] = false;
    }
    setIsToggle(copy)
  }


  /**
   * This method sets isToggle state after onClick action
   * @param i : Index of section
   */
  const toggle = (i) => {
    let tempIndex : Boolean[] = [];
    tempIndex = [...isToggle]
    tempIndex[i] = !isToggle[i]
    setIsToggle(tempIndex)
  }

  useEffect(() => {
    initialize();
    console.log("use effect")
  },[])


  return (
    <>
      <ul className="slds-accordion">
        {data > 0 ?
          map(range(data), i => (

            <li key={i} className="slds-accordion__list-item" >
              <section id={'cq_section' + i} className={"slds-accordion__section cq-accordion" + (isToggle[i] === false ? " slds-is-open" : "")}>
                <CQSectionHeader title={<ResolvedJsonFormsDispatch
                  schema={schema}
                  uischema={uischema.options.headerLayout}
                  path={composePaths(path, `${i}`)}
                  renderers={renderers}
                  key={i}
                  enabled={enabled}
                />} toggle={toggle} index={i} isToggle={isToggle[i]}/>
                
                
                <div className="slds-accordion__content">
                  {
                    uischema.options.guides !== undefined ?
                      <ResolvedJsonFormsDispatch
                        schema={schema}
                        uischema={getGuideSchema(uischema, composePaths(path, `${i}`))}
                        path={composePaths(path, `${i}`)}
                        renderers={renderers}
                        enabled={enabled}
                      />
                      : null
                  }
                  <ResolvedJsonFormsDispatch
                    schema={schema}
                    uischema={childUISchema}
                    path={composePaths(path, `${i}`)}
                    renderers={renderers}
                    key={i}
                    enabled={enabled}
                  />
                </div>
              </section>
            </li>
          )) : ''

        }
      </ul>
    </>
  )
}

export default withJsonFormsArrayLayoutProps(CQSection);