// fork of https://raw.githubusercontent.com/eclipsesource/jsonforms/4b9bbbd2120e4d404f6e0a814c0c604e52f727bd/packages/material/src/layouts/ArrayToolbar.tsx

import { Grid, Hidden, Tooltip } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import ValidationIcon from './ValidationIcon';
import Button from '@salesforce/design-system-react/components/button';
import { isSalesforceBuild } from '../salesforceBuild';

export interface ArrayLayoutToolbarProps {
  label: string;
  errors: string;
  path: string;
  addItem(path: string, data: any): () => void;
  createDefault(): any;
  handleActiveTabChange: () => void;
}
export const ArrayLayoutToolbar = React.memo(
  ({
    label,
    errors,
    addItem,
    path,
    createDefault,
    handleActiveTabChange
  }: ArrayLayoutToolbarProps) => {
    return (
      <Toolbar disableGutters={true}>
        <Grid container alignItems='center' justify='space-between'>
          <Grid item>
            <Typography variant={'h6'}>{label}</Typography>
          </Grid>
          <Hidden smUp={errors.length === 0}>
            <Grid item>
              <ValidationIcon id='tooltip-validation' errorMessages={errors} />
            </Grid>
          </Hidden>
          <Grid item>
            <Grid container>
              <Grid item>
                <Tooltip
                  id='tooltip-add'
                  title={`Add to ${label}`}
                  placement='bottom'
                >
                  { !isSalesforceBuild() ?
                    <Button
                      label="Add"
                      aria-label={`Add to ${label}`}
                      onClick={addItem(path, createDefault())}
                      onMouseUp={handleActiveTabChange}  //used this event to handle tab change
                    />
                    :
                    <></>
                  }
                  
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    );
  }
);