import React from "react";
import formBuilderScreenStrings from 'localizations/formBuilderScreen';
import Icon from '@salesforce/design-system-react/components/icon';
import Checkbox from '@salesforce/design-system-react/components/checkbox';
import { withJsonFormsControlProps } from "@jsonforms/react";
import CQEmptyStartingData from "./CQEmptyStartingData";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { CQSectionContext } from "./CQSectionContext";
import Button from '@salesforce/design-system-react/components/button';

const Wrapper = (props: any) => {
    // style used for drag icon
    const dragIconStyle = {
        width: '2rem'
    }

    const reducedSize = {
        zoom: '1.0'
    };

    // style used for dragged field
    const dragItemStyle = {
        alignItems: 'center',
        height : '6rem'
    }

    const isSection = props.uischema.type === 'Section' ;
    const index = (props.uischema && props.uischema.options && props.uischema.options.id) || 1;

    const handleClick = (event) => {
        if (event.detail === 2) { //check if user has clicked double to edit field or not
            // creating event and dipsatching it to update schema on editing field
            const updateSchema = new CustomEvent("cqupdateschema",{
                bubbles : true,
                detail : {
                    schema : props.schema,
                    uischema: props.uischema
                },
            })
            document.dispatchEvent(updateSchema);
        }
    }

  return (
    <>

        {props.wrapperenabled ?
            <div className={isSection ? "" : "slds-p-around_xx-small"}>
                <CQSectionContext.Consumer>
                {(value) => (
                    !value.insideSection ?
                    <Draggable key={props.path} draggableId={props.path} index={index}> 
                    {(provided, snapshot) => (
                        <div className="slds-box slds-theme_default" 
                        
                         ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                        >
                        <div className="slds-grid">                            
                                    <div className={isSection ? "slds-size_9-of-12 slds-grid slds-wrap" : "slds-size_11-of-12 slds-grid slds-wrap"} style={dragItemStyle} onClick={handleClick}>
                                        <div className="slds-size_1-of-12 slds-p-vertical_small" style={dragIconStyle}>
                                            { isSection ? <></> : 
                                                <Icon
                                                    assistiveText={{ label: formBuilderScreenStrings.dragLabel }}
                                                    category="utility"
                                                    colorVariant="default"
                                                    name="drag_and_drop"
                                                    size="x-small"
                                                /> }
                                        </div>
                                        <div className="slds-size_10-of-12">
                                            <div style={reducedSize}>
                                                {props.children}
                                            </div>
                                            <div className="slds-show_inline-block">
                                                <Checkbox
                                                    assistiveText={{
                                                        label: formBuilderScreenStrings.readOnlyLabel,
                                                    }}
                                                    labels={{
                                                        label: formBuilderScreenStrings.readOnlyLabel,
                                                    }}
                                                    checked = {props.uischema.options.readOnly || props.uischema.options.isReferenceField}
                                                    onChange={(event) => {
                                                        const makeFieldReadOnly = new CustomEvent("cqreadonlyrequireduischema",{
                                                            bubbles : true,
                                                            detail : {
                                                                uischema : props.uischema,
                                                                value : event.target.checked,
                                                                type : formBuilderScreenStrings.readOnlyLabel
                                                            },
                                                        })
                                                        // added condition to dispatch event only for main object fields
                                                        if(!props.uischema.options.isReferenceField) {
                                                            document.dispatchEvent(makeFieldReadOnly)
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className="slds-show_inline-block">
                                                <Checkbox
                                                    assistiveText={{
                                                        label: formBuilderScreenStrings.requiredLabel,
                                                    }}
                                                    labels={{
                                                        label: formBuilderScreenStrings.requiredLabel,
                                                    }}
                                                    checked = {props.required}
                                                    onChange={(event) => {
                                                        const makeFieldRequired = new CustomEvent("cqreadonlyrequireduischema",{
                                                            bubbles : true,
                                                            detail : {
                                                                uischema : props.uischema,
                                                                value : event.target.checked,
                                                                type : formBuilderScreenStrings.requiredLabel
                                                            },
                                                        })
                                                        document.dispatchEvent(makeFieldRequired)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={isSection ? "slds-size_3-of-12" : "slds-size_1-of-12 slds-text-align_right"} >
                                            
                                            {isSection ? <CQEmptyStartingData {...props}></CQEmptyStartingData> : 
                                                <Button
                                                    iconCategory="utility"
                                                    iconName="delete"
                                                    iconSize="small"
                                                    variant="icon"
                                                    onClick={(evt) => {
                                                        const deleteUISchema = new CustomEvent("cqdeleteuischema",{
                                                            bubbles : true,
                                                            detail : {uischema : props.uischema},
                                                        })
                                                        document.dispatchEvent(deleteUISchema)
                                                    }}
                                                />
                                              }
                                    </div>
                                </div>
                        </div>
                    )}
                    </Draggable>
                : <>{props.children}</>
                )}
                </CQSectionContext.Consumer>
            </div>
            
            
        :
        <>{props.children}</>}
    </>
  );
};


const WrapperWithProps = withJsonFormsControlProps(Wrapper);


export function EditableWrapper(renderer, isEnabled: boolean = true) : React.ComponentType<any> {
    return (...args) => {
        let modifiedArgs :any = JSON.parse(JSON.stringify(args));
        modifiedArgs[0].enabled = false; // always switch to readonly mode when editing
        return (
            <WrapperWithProps {...modifiedArgs[0]} wrapperenabled={isEnabled} >
            {renderer(...modifiedArgs)}
        </WrapperWithProps>);
    }
}

const NoDropWrap = (props: any) => {
    
    const isSection = props.uischema.type === 'Section' ;
    return (
        <Droppable key={props.path} droppableId={props.path} type="NODROPZONE" direction="horizontal"> 
            {(provided) => (
                <div className="slds-box slds-theme_default" ref={provided.innerRef} {...provided.droppableProps}
                    >
                    <div className="slds-grid slds-wrap">
                                <div className={isSection ? "slds-size_9-of-12 slds-grid slds-wrap" : "slds-size_11-of-12 slds-grid slds-wrap"} >
                                    <div style={{width: "100%"}}>
                                        {props.children}
                                    </div>
                                </div>
                                <div className={isSection ? "slds-size_3-of-12" : "slds-size_1-of-12 slds-text-align_right"} >
                                        {isSection ? <CQEmptyStartingData {...props}></CQEmptyStartingData> : <Icon
                                            assistiveText={{ label: formBuilderScreenStrings.deleteLabel }}
                                            category="utility"
                                            colorVariant="default"
                                            name="delete"
                                            size="x-small"
                                            />}
                                </div>
                    </div>
                </div>
            )}
            </Droppable>

    );
}

const NoDropWrapWithProps = withJsonFormsControlProps(NoDropWrap);

export function NoDropWrapper(renderer, isEnabled: boolean = true) : React.ComponentType<any> {
    return (...args) => {
        let modifiedArgs :any = JSON.parse(JSON.stringify(args));
        modifiedArgs[0].enabled = false; // always switch to readonly mode when editing
        return (
            <CQSectionContext.Provider value={{insideSection: true}}>
                <NoDropWrapWithProps {...modifiedArgs[0]} >
                    {renderer(...modifiedArgs)}
                </NoDropWrapWithProps>
            </CQSectionContext.Provider>);
    }
}