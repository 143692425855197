import React, { useCallback, useState, useContext } from 'react';
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone';
import map from 'lodash/map';
import range from 'lodash/range';
import { Button, Grid, Box } from '@material-ui/core';
import Delete from '../assets/slds/icons/action/delete_60.png';
import strings from '../localizations/formScreen'
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Icon from '@salesforce/design-system-react/components/icon'; 
import { isSalesforceBuild } from '../salesforceBuild';
import Spinner from '@salesforce/design-system-react/components/spinner';

import {
  ResolvedJsonFormsDispatch,
  withJsonFormsArrayLayoutProps
} from '@jsonforms/react';


import {
  composePaths
} from '@jsonforms/core';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import CQFileUploaderContext from '../context/CQFileUploaderContext';
import { FILE_SIZE, fileSelected, fileUploaderprops, getSchema } from 'common/fileUploader';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deleteButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      float: 'right',
      top: '3rem',
      right: '-0.5rem'
    },
    file: {
      display: 'flex',
      justifyContent: 'flex-start',
      width: "8rem"
    },
    icon: {
      width: '20px',
      height: '20px',
      margin: '10px',
    },
    text: {
      fontSize: '16px',
      padding: '5px'
    },
    attachFile: {
      display: 'none',
      visibility: 'hidden',
    },
    files: {
      display: 'flex',
      alignItems: 'center', 
      backgroundColor: 'rgba(211, 211, 211, 0.7)',
      borderRadius: '5px',
      padding : '2px',
      paddingLeft : '30px',
      color: '#2D9CDB'
    },
    delete: {
      width: '20px',
      height: '20px',
    },
    errorMessage:{
      color: "red", 
      padding: 5, 
      margin: 0, 
      fontSize: "x-small"
    }
  }),
);


const CQFileUploadControl = ({
  uischemas,
  schema,
  uischema,
  path,
  errors,
  visible,
  label,
  required,
  removeItems,
  addItem,
  data,
  renderers,
  cells,
  config,
  enabled,
  dispatch }: any) => {
  const classes = useStyles();

  enabled = window.location.pathname.indexOf('selected-submission') === -1 && enabled;

  const getStyles = (uischema) => {
    let style = {};
    if (uischema.options?.style === "container") {
      style = { 
        "border": "1px solid lightgray",
        "padding": "1rem",
        "minHeight": "5rem",
        "marginTop": "1rem"
      }
    } else {
      style = {
        "display": "flex",
        "flexDirection": "row",
        "alignItems": "baseline",
        "gap": "1rem"
      }
    }
    return style;
  }

  const getClasses = (uischema) => {
    let classes = "";

    if (uischema.options?.alignment) {
      classes=`slds-align_absolute-${uischema.options?.alignment} slds-m-top_large`
    }

    return classes;
  }

  const [uploading, setUploading] = useState(false);

  const fileUploaderContext = useContext(CQFileUploaderContext);
  const [fileErrors, setFileErrors] = useState("");

  const handleRemoveItem = useCallback(
    (p: string, value: any) => () => {

      if (!window.confirm('Are you sure ?')) {
        return;
      }

      removeItems(p, [value])();
    },
    [removeItems]
  );

  const handleDrop = (acceptedFiles:any[], fileRejections) => {
    let fileArguments: fileUploaderprops = {
      acceptedFiles,
      fileRejections,
      setFileErrors,
      setUploading,
      addItem,
      fileUploaderContext,
      path
    }
    fileSelected(fileArguments);
  }

  return (
    <>
      {uploading ?
        <Spinner
        size="small"
        variant="base"
        assistiveText={{ label: strings.uploading }}
      /> : ''}
    <div style={getStyles(uischema)}>
      <Grid container spacing={2} style={{order: 2}}>
        {data > 0 ?
          map(range(data), index => (<Grid item key={index}>
            <Box component="div" className={classes.files}>
              <ResolvedJsonFormsDispatch
                renderers={renderers}
                cells={cells}
                visible={visible}
                schema={schema}
                uischema={getSchema()}
                path={composePaths(path, `${index}`)}
                enabled={enabled}
              />
              {enabled ?
                <Button onClick={handleRemoveItem(path, index)}>
                  <img className={classes.delete} src={Delete} /></Button> : ''}
            </Box>
          </Grid>
          ))
          : ''}
      </Grid>
      <div className={getClasses(uischema)} style={{order: 1}}>
        <p className={classes.errorMessage}>
          {fileErrors}
        </p>
        {enabled?
        <Dropzone onDrop={handleDrop} maxSize={FILE_SIZE} disabled={!enabled}>

          {({ getRootProps, getInputProps }) => (
            <section className={classes.file}>
              <div {...getRootProps()}>
                  <input type="file" {...getInputProps()} multiple disabled={isSalesforceBuild()}/>
                <IconSettings iconPath="/assets/icons">
                  <Icon
                    className="cq-footer-icon"
                    assistiveText={{ label: strings.attachFile }}
                    category="utility"
                    name="attach"
                    size="x-small"
                    />
                </IconSettings>
                <label htmlFor="filefield" className="slds-text-body_regular slds-size_full cq-footer-anchor">{strings.attachFile}</label>
              </div>
            </section>
          )}
        </Dropzone>:<Icon
                    className="cq-footer-icon"
                    assistiveText={{ label: strings.attachFile }}
                    category="utility"
                    name="attach"
                    size="x-small"
                    />}
      </div>
    </div>
    </>
  )
};


export default withJsonFormsArrayLayoutProps(CQFileUploadControl);