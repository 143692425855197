import React from 'react';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { isSalesforceBuild } from '../salesforceBuild';
import Input from '@salesforce/design-system-react/components/input'; 

const CQInputControl = ({data, handleChange, path, required, visible,label, enabled, uischema, errors, schema}: any) => {
    const handleInputChange = (evt : any) => {
      if (!evt.target.value.trim().length) {
        handleChange(path, undefined);
        data= ""; // update the data to empty to avoid showing previous value as input doesn't take undefined as input value
      } else {
        data = evt.target.value;
        handleChange(path, data);
      }
    }
  return (
  <>
  {visible?
  <>
    <Input
      label={label}
      required={required}
      onInput={handleInputChange}
      errorText={errors}
      value={data}
      disabled={isSalesforceBuild() || !enabled}
    /> </>:''}
  </>
  );
};
export default withJsonFormsControlProps(CQInputControl);