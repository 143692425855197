import React, { useCallback, useEffect, useState } from 'react';
import Icon from '@salesforce/design-system-react/components/icon';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import CQLabel from '../../renderer/CQLabel';
import Modal from '@salesforce/design-system-react/components/modal'; 
import Button from '@salesforce/design-system-react/components/button';
import strings from '../../localizations/formScreen';
import { isSalesforceBuild } from '../../salesforceBuild';
import { CQAppConstant } from 'app-constants';


/**
 * Component used to show value of 1st field and represents the child item in the right side panel.
 */
function CQListDetailPanel({
    schema,
    uischema,
    path,
    removeItems,
    index,
    activeItemIndex,
    setCurrentIndex
  }: any) {

    // style used for inactive/unfocued list item on the left panel
    const listItemCustomStyle = {
        paddingTop: "10px",
        paddingBottom: "10px",
        cursor: "default"
    }

    // style used for active/focused item on the left panel
    const activeItemCustomStyle = {
        paddingTop: "10px",
        paddingBottom: "10px",
        backgroundColor: "rgb(32 151 255 / 20%)",
        borderRadius: "6px",
        cursor: "default"
    }

    const hideDeleteIconStyle = {
        display: "none"
    }
    const deleteIconStyle = {
        cursor: "pointer"
    }

    const [isDeleteClick, setIsDeleteClick] = useState(false);

    // used to delete item from the list
    const deleteItem = (path: string, idx: number) =>{
        path = path.split('.'+index)[0]
        removeItems(path, [idx])();
        toggle(idx - 1);
    }

    // used to show/hide delete confirmation popup by changing the boolean
    const toggle = (idx: number)  => {
        setCurrentIndex(idx)
        setIsDeleteClick(!isDeleteClick);
    }

    //called the dispatch event and stored the latest index value.
    const updateIndex = new CustomEvent(CQAppConstant.EVENT_LISTENER.CQ_UPDATE_INDEX,{
        bubbles : true,
        detail : {
            currentIndex: index,
        },
    });

    useEffect(()=> {
        document.dispatchEvent(updateIndex);
    },[index]);

    return (!isDeleteClick ? 
        (
            <>
                <div className="slds-grid slds-gutters" style={index === activeItemIndex ? activeItemCustomStyle : listItemCustomStyle} >
                    <div className="slds-col slds-size_10-of-12 slds-grid" >
                        <label className='slds-progress-ring slds-text-align_center slds-m-right_large'>{index+1}</label>
                        <div className='slds-truncate'>
                            <CQLabel schema={schema} uischema={uischema.elements[0].titleLayout} path={path} />
                    </div>
                    
                    </div>
                    <div className="deleteCustomListItem slds-size_2-of-12 slds-text-align_center" onClick={() => toggle(index)} style={isSalesforceBuild() ? hideDeleteIconStyle : deleteIconStyle}>
                        <IconSettings iconPath="/assets/icons">
                            <Icon
                                assistiveText={{ label: 'delete' }}
                                category="utility"
                                colorVariant="default"
                                name="delete"
                                size="x-small"
                            />
                        </IconSettings>
                    </div>
                </div>
            </>
        ) : 
        (
            <>
                <Modal
                    isOpen={true}
                    footer={[
                        <Button label={strings.no} onClick={() => toggle(index)} />,
                        <Button label={strings.yes} variant="brand" onClick={() => deleteItem(path, index)} />,
                    ]}
                    onRequestClose={() => toggle(index)}
                    heading={strings.confirmation}
                >
                    <section className="slds-p-around_large slds-m-bottom_large">
                        <div className="slds-grid slds-gutters slds-grid_align-center">
                            {strings.deleteConfirmationMsg}
                        </div>
                    </section>
                </Modal>
            </>
        )
    );
}

export default CQListDetailPanel;
