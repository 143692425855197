import { IFormDef } from 'interfaces/ITemplate';
import { CQFSchemaBuilder } from 'components/editor/schemaBuilder';
import {CQAppConstant} from 'app-constants';

/**
 * This method helps to generate new schema when invoked
 * @param formDef form definition
 * @param module module name
 * @returns updated formDef
 */
export const refreshSchema = async (formDef: IFormDef) => {
    try{
        if(formDef && formDef.cqFormObjectSchema && formDef.version !== "1.0.0"){
            // line 17 to 27 gets the selected/dragged fields(api names) from formDef and use it in AUDIT_SCHEMA/SAFETY_INSPECTION_SCHEMA schema variables
            let keys : any = formDef.schema ? formDef.schema['properties'] : null;
            if(keys){
                let objectKey : any = Object.keys(keys);
                if(objectKey.indexOf(CQAppConstant.GENERAL) > -1){
                    let generalIndex : number = objectKey.indexOf(CQAppConstant.GENERAL);
                    objectKey.splice(generalIndex, 1);
                }
                if(objectKey.length){
                    let fieldKeys : any = Object.keys(keys[objectKey].properties);
                    fieldKeys = fieldKeys.filter(element => !element.endsWith('__r'));
                    fieldKeys = fieldKeys.map((element) => {
                        return element.toLowerCase();
                    });
                    
                    let result :any;
                    let schemaBuilder = new CQFSchemaBuilder();
                    formDef.cqFormObjectSchema['fields'] = fieldKeys
                    result = await schemaBuilder.apiCall(formDef.cqFormObjectSchema, 'object-info', []);
                    if(result){
    
                        //definitions
                        let definitionObjectKeys : any = Object.keys(result.definitions);
                        definitionObjectKeys.forEach((key:string) => {
                            let objectProps : any = JSON.parse(JSON.stringify(result.definitions[""+key]["properties"][""+key]));
                            if(typeof objectProps === "object"){
                                let objectInnerKeys: any = Object.keys(objectProps["properties"]);
                                objectInnerKeys.forEach((innerKey:string) => {
                                    let defObject : any = JSON.parse(JSON.stringify(objectProps["properties"][''+innerKey]));
                                    if(typeof defObject === "object" && formDef["schema"]["definitions"][""+key]["properties"][""+key]["properties"][""+innerKey]){
                                        if((formDef["schema"]["definitions"][""+key]["properties"][""+key]["properties"][""+innerKey]).hasOwnProperty('validFor')) {
                                            // holds value for child object with validFor property
                                            let validForParentField = formDef["schema"]["definitions"][""+key]["properties"][""+key]["properties"][""+innerKey];
                                            Object.keys(validForParentField['validFor']).map((key:string) => {
                                                validForParentField['validFor'][key].map((innerObject:any) => {
                                                    // check whether validFor options has color as property or not
                                                    if(innerObject.hasOwnProperty('color')) {                 
                                                        defObject['validFor'][key].map((defInnerObject:any) => {
                                                            // check if updated title and object title are same to add color property
                                                            if(defInnerObject['title'] === innerObject['title']) {
                                                                defInnerObject['color'] = innerObject['color'] // add color property to updated option
                                                            }

                                                        })
                                                    }
                                                })
                                            })
                                        }
                                        
                                        // check the form type is Audit or not and update picklist values
                                        if(formDef.cqFormType === "compliancequest__SQX_Audit__c" && (formDef["schema"]["definitions"][""+key]["properties"][""+key]["properties"][""+innerKey]).hasOwnProperty('oneOf')) {
                                            // holds value for child object with oneOF property
                                            let oneOfParentField = formDef["schema"]["definitions"][""+key]["properties"][""+key]["properties"][""+innerKey];
                                            Object.keys(oneOfParentField["oneOf"]).map((key:string) => {
                                                let innerObject: any = oneOfParentField["oneOf"][key]
                                                // check whether oneOF options has color as property or not
                                                if(innerObject.hasOwnProperty('color')) {                 
                                                    defObject["oneOf"].map((defInnerObject:any) => {
                                                        // check if updated title and object title are same to add color property
                                                        if(defInnerObject['title'] === innerObject['title']) {
                                                            defInnerObject['color'] = innerObject['color'] // add color property to updated option
                                                        }

                                                    })
                                                }
                                            })
                                        }
                                       formDef["schema"]["definitions"][""+key]["properties"][""+key]["properties"][""+innerKey] = defObject;
                                    }
                                });
                            }
                        });
    
                        //properties
                        let ObjectKeys : any = Object.keys(result.properties);
                        ObjectKeys.forEach((key:string) => {
                            if(typeof result.properties[""+key] === "object"){
                                let objectProps : any = result.properties[""+key]["properties"];
                                let innerKeys : any = Object.keys(objectProps);
                                innerKeys.forEach((innerkey:string) => {
                                    if(typeof objectProps[""+innerkey] === "object" && formDef["schema"]["properties"][key]["properties"][""+innerkey]){
                                        formDef["schema"]["properties"][key]['properties'][""+innerkey] = objectProps[""+innerkey];
                                    }
                                });
                            }else{
                                formDef.schema[""+key] = result[""+key];
                            }
                        })
                    }else if(!result) return;
                }
            }
        }
    }catch(error){
        console.log('error', error);
    }
    return formDef;
}