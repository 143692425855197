import React from 'react';
import '../assets/slds/styles/salesforce-lightning-design-system.css'
import strings from '../localizations/homeScreen';

const CQHeader = (props) => {

  return (
    <div className="slds-page-header">
      <div className="slds-page-header__row">
        <div className="slds-page-header__col-title">
          <div className="slds-media">
            <div className="slds-media__figure">
              <span className="slds-icon_container">
                <img className="cq-form-logo" src={props.logo} alt={strings.logoAltText}/>
              </span>
            </div>
            <div className="slds-media__body">
              <div className="slds-page-header__name">
                <div className="slds-page-header__name-title">
                  <h1>
                    <span className="slds-page-header__title slds-truncate">{props.header}</span>
                  </h1>
                </div>
              </div>
              <p className="slds-page-header__name-meta">{props.subheader}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CQHeader;