import React, { useCallback, useState, useContext } from "react";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone";
import map from "lodash/map";
import range from "lodash/range";
import { Button, Grid, Box } from "@material-ui/core";
import Delete from "../assets/slds/icons/action/delete_60.png";
import strings from "../localizations/formScreen";
import IconSettings from "@salesforce/design-system-react/components/icon-settings";
import Icon from "@salesforce/design-system-react/components/icon";
import Spinner from "@salesforce/design-system-react/components/spinner";

import {
  ResolvedJsonFormsDispatch,
  withJsonFormsArrayLayoutProps,
} from "@jsonforms/react";

import { composePaths } from "@jsonforms/core";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import CQFileUploaderContext from "../context/CQFileUploaderContext";
import { isSalesforceBuild } from "salesforceBuild";
import { FILE_SIZE, fileSelected, fileUploaderprops, getSchema } from "common/fileUploader";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deleteButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      float: "right",
      top: "3rem",
      right: "-0.5rem",
    },
    file: {
      display: "flex",
      justifyContent: "flex-start",
      width: "8rem",
    },
    icon: {
      width: "20px",
      height: "20px",
      margin: "10px",
    },
    text: {
      fontSize: "16px",
      padding: "5px",
    },
    attachFile: {
      display: "none",
      visibility: "hidden",
    },
    files: {
      display: "flex",
      alignItems: "center",
      backgroundColor: "rgba(211, 211, 211, 0.7)",
      borderRadius: "5px",
      padding: "2px",
      paddingLeft: "30px",
      color: "#2D9CDB",
    },
    delete: {
      width: "20px",
      height: "20px",
    },
    errorMessage: {
      color: "red",
      padding: 5,
      margin: 0,
      fontSize: "x-small",
    },
  })
);

const CQGenericFileUploadControl = ({
  uischemas,
  schema,
  uischema,
  path,
  errors,
  visible,
  label,
  required,
  removeItems,
  addItem,
  data,
  renderers,
  cells,
  config,
  enabled,
  dispatch,
}: any) => {
  const classes = useStyles();

  const isEnabled = window.location.pathname.indexOf("selected-submission") === -1 && enabled;

  const [uploading, setUploading] = useState(false);

  const fileUploaderContext = useContext(CQFileUploaderContext);
  const [fileErrors, setFileErrors] = useState("");

  const handleDrop = (acceptedFiles:any[], fileRejections) => {
    let fileArguments: fileUploaderprops = {
      acceptedFiles,
      fileRejections,
      setFileErrors,
      setUploading,
      addItem,
      fileUploaderContext,
      path
    }
    fileSelected(fileArguments);
  }

  const handleRemoveItem = useCallback(
    (p: string, value: any) => () => {
      if (!window.confirm("Are you sure ?")) {
        return;
      }

      removeItems(p, [value])();
    },
    [removeItems]
  );


  return (
    <>
      {uploading ? (
        <Spinner
          size="small"
          variant="base"
          assistiveText={{ label: strings.uploading }}
        />
      ) : (
        ""
      )}
      {isEnabled ? (
        <div className="slds-grid slds-p-vertical_small slds-m-top_xx-small">
          <span className="slds-size_12-of-12 slds-box slds">
            <Grid container spacing={2} style={{ order: 2 }}>
              {data > 0
                ? map(range(data), (index) => (
                    <Grid item key={index}>
                      <Box component="div" className={classes.files}>
                        <ResolvedJsonFormsDispatch
                          renderers={renderers}
                          cells={cells}
                          visible={visible}
                          schema={schema}
                          uischema={getSchema()}
                          path={composePaths(path, `${index}`)}
                          enabled={enabled}
                        />
                        {enabled ? (
                          <Button onClick={handleRemoveItem(path, index)}>
                            <img className={classes.delete} src={Delete} />
                          </Button>
                        ) : (
                          ""
                        )}
                      </Box>
                    </Grid>
                  ))
                : ""}
            </Grid>
            <span className="slds-size_11-of-12">
              <p className={classes.errorMessage}>{fileErrors}</p>
              <Dropzone onDrop={handleDrop} maxSize={FILE_SIZE}>
                {({ getRootProps, getInputProps }) => (
                  <section className="slds-align_absolute-center slds-p-top_small">
                    <div {...getRootProps()} className="slds-box slds-grid">
                      <input
                        type="file"
                        {...getInputProps()}
                        multiple
                        disabled={isSalesforceBuild()}
                      />
                      <IconSettings iconPath="/assets/icons">
                        <Icon
                          className="cq-footer-icon"
                          assistiveText={{ label: strings.uploadFiles }}
                          category="utility"
                          name="upload"
                          size="x-small"
                        />
                      </IconSettings>
                      <label
                        htmlFor="filefield"
                        className="slds-text-heading slds-size_full cq-footer-anchor"
                      >
                        {strings.uploadFiles}
                      </label>
                    </div>
                  </section>
                )}
              </Dropzone>
              <div className="slds-align_absolute-center slds-m-top_x-small">
                <label className="slds-text-heading_small">
                  {strings.attachOrDrop}
                </label>
              </div>
            </span>
          </span>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default withJsonFormsArrayLayoutProps(CQGenericFileUploadControl);
