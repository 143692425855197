import '../assets/slds/styles/salesforce-lightning-design-system.css'
import React from 'react';
import { ResolvedJsonFormsDispatch, withJsonFormsLayoutProps } from '@jsonforms/react';

const CQGrid = (props) => {
  const { uischema, schema, path, visible, renderers, enabled } = props;

  return (
    <div>
      <article className="slds-card slds-card_boundary">
        <div className="slds-p-around--large">
          {visible &&
            uischema.elements.map((child, index) => (
              <ResolvedJsonFormsDispatch
                schema={schema}
                uischema={child}
                path={path}
                renderers={renderers}
                key={index}
                enabled={enabled}
              />
            ))}
        </div>
      </article>
    </div>
  );
}

export default withJsonFormsLayoutProps(CQGrid);