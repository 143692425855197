import React, { useEffect, useRef, useState } from "react";
import Modal from "@salesforce/design-system-react/components/modal";
import formBuilderScreenStrings from "localizations/formBuilderScreen";
import Icon from "@salesforce/design-system-react/components/icon";

// incoming props for generic field modal with type
interface CQFormBuilderModalProps {
  isDropdown: boolean;
  setIsChildDropdown: ()=> void;
  openModal: boolean;
  closeModal: () => void;
  title: string;
  fieldLabel: string;
  handleChange: (evt) => void;
  handleSave: () => void;
  dropdownValues: Array<object>;
  handleDropDownChange: (evt) => void;
  dropdownInput: string;
  saveDropDownValues: () => void;
  removeDropDownValue: (index) => void;
}

export default function CQGenericFieldModal({
  handleChange,
  fieldLabel,
  isDropdown,
  setIsChildDropdown,
  openModal,
  closeModal,
  title,
  handleSave,
  dropdownValues,
  dropdownInput,
  handleDropDownChange,
  saveDropDownValues,
  removeDropDownValue,
}: CQFormBuilderModalProps) {
  const [currentDropdownValues, setCurrentDropdownValues] = useState(dropdownValues);
  const isEditedDropdown = useRef(isDropdown);

  // style used for section of modal appears when editing field
  const modalSectionStyle = {
    padding: "1rem",
  };

  // style used for modal text input
  const modalInputStyle = {
    width: "100%",
  };

  // style used for modal dropdown input
  const modalDropDownInputStyle = {
    width: "55%",
  };

  // style used for dropdown label style
  const dropdownLabelStyle = {
    borderBottom: "1px solid black",
    fontSize: "1rem",
  };

  // style used for modal footer section
  const modalFooterStyle = {
    display: "flex",
    justifyContent: "flex-end",
  };

  useEffect(() => {
    setCurrentDropdownValues(dropdownValues);
    isEditedDropdown.current = true;
    setIsChildDropdown();
  }, [dropdownValues])

  return (
    <Modal
      dismissOnClickOutside={false}
      isOpen={openModal}
      onRequestClose={closeModal}
      title={title}
      ariaHideApp={false}
    >
      {!isEditedDropdown.current ? ( // load modal respectively by checking if editing field is a dropdown 
        <section className="slds-p-around_medium">
          <div className="slds-gutters" style={modalSectionStyle}>
            <p className="slds-text-heading_small slds-m-bottom_x-small">
              {formBuilderScreenStrings.fieldLabel}
            </p>
            <input
              placeholder={formBuilderScreenStrings.generalFieldPlaceholder}
              className="slds-p-around_x-small"
              style={modalInputStyle}
              value={fieldLabel}
              onChange={(e) => handleChange(e)}
            />
            <div
              className="slds-grid slds-m-top_large"
              style={modalFooterStyle}
            >
              <button
                className="slds-button slds-button_neutral"
                onClick={closeModal}
              >
                {formBuilderScreenStrings.cancel}
              </button>
              <button
                className="slds-button slds-button_brand"
                onClick={handleSave}
              >
                {formBuilderScreenStrings.save}
              </button>
            </div>
          </div>
        </section>
      ) : (
        <section className="slds-p-around_medium">
          <div className="slds-gutters" style={modalSectionStyle}>
            <p className="slds-text-heading_small slds-m-bottom_x-small">
              {formBuilderScreenStrings.fieldLabel}
            </p>
            <input
              placeholder={formBuilderScreenStrings.generalFieldPlaceholder}
              className="slds-p-around_x-small"
              style={modalDropDownInputStyle}
              value={fieldLabel}
              onChange={(e) => handleChange(e)}
            />
          {isDropdown?(
            <div className="slds-m-top_large">
              <p className="slds-m-bottom_small slds-text-heading_small">
                {formBuilderScreenStrings.picklistValues}
              </p>
              <section className="slds-grid">
                <input
                  className="slds-col slds-size_10-of-12 slds-box slds-m-right_xx-small"
                  placeholder={formBuilderScreenStrings.picklistPlaceholder}
                  value={dropdownInput}
                  onChange={(e) => handleDropDownChange(e)}
                />
                <button
                  className="slds-col slds-size_2-of-12 slds-button slds-button_neutral"
                  onClick={saveDropDownValues}
                >
                  {`+ ${formBuilderScreenStrings.add}`}
                </button>
              </section>
              <section className="slds-box slds-m-top_small">
                  {currentDropdownValues.map((value, index) => (
                  <section className="slds-grid slds-m-bottom_small">
                    <label
                      className="slds-col slds-size_10-of-12 slds-m-right_small"
                      style={dropdownLabelStyle}
                    >
                      {value["title"]}
                    </label>
                    <span
                      onClick={() => removeDropDownValue(index)}
                      className="slds-col slds-size_1-of-12  slds-button"
                    >
                      <Icon
                        assistiveText={{ label: "delete" }}
                        category="utility"
                        colorVariant="default"
                        name="delete"
                        size="x-small"
                        className="$font-size-11"
                      />
                    </span>
                  </section>
                ))}
              </section>
            </div>):''}
            <div
              className="slds-grid slds-m-top_large"
              style={modalFooterStyle}
            >
              <button
                className="slds-button slds-button_neutral"
                onClick={closeModal}
              >
                {formBuilderScreenStrings.cancel}
              </button>
              <button
                className="slds-button slds-button_brand"
                onClick={handleSave}
              >
                {formBuilderScreenStrings.save}
              </button>
            </div>
          </div>
        </section>
      )}
    </Modal>
  );
}
