import { refreshSchema } from "./refreshSchema";
const MINUS = '-';
const PLUS = '+';
const ZERO = '0';
const DOLLAR_TODAY = '$today'
const DOLLAR_CONTEXT_DOT = '$context.'
const SOURCE = 'source'
const OBJECT = 'object'
const STRING = 'string'
const RELNS = 'relns'
const RECORD = 'record'
const CURRENT_USER = '$currentuser'
const DOCUMENT_NAME = '$documentname'
const USER_FULLNAME = 'fullName';

const getUserDetails = () => {
    let userDetails:any;
    // get user details from localstorage and parse it to JSON format
    let userDetailsStr: any = localStorage.getItem('userDetails') ? localStorage.getItem('userDetails') : null
    if(userDetailsStr !== null && userDetailsStr !== undefined){
        userDetails = JSON.parse(userDetailsStr);
    }else{
        userDetailsStr = localStorage.getItem('userDetailsSF')  ? localStorage.getItem('userDetailsSF') : null;
        userDetails = JSON.parse(userDetailsStr);
    }

    return userDetails;
}

/*
 * convert date to ISO string 
 */
export const getDateString = (date: Date) =>{
   return date?date.toISOString().substring(0,10): new Date().toISOString().substring(0,10);
}

/*
 * recursive method to fill the values in data from starting data with mapping
 */
export const fillInValues = (data: any, startingData: any, mapping: any, submission?:any) => {
    Object.keys(mapping).forEach((key) => {
        if (key === SOURCE) {
            return;
        }
        let newData: any = data[key] || {};
        if (typeof (mapping[key]) === OBJECT) {
            if (mapping[key][SOURCE]) {
                let objKey: any = mapping[key][SOURCE].replace(DOLLAR_CONTEXT_DOT, "");
                if (startingData[objKey]) {
                    let values: any = startingData[objKey].records;
                    newData =  data[key] || [];
                    values.forEach((value: any) => {
                        let record = {};
                        fillInValues(record, value, mapping[key], submission);
                        newData.push(record);
                    });
                }

            } else {
                fillInValues(newData, startingData, mapping[key], submission);
            }
            if (typeof newData === OBJECT && Object.keys(newData).length === 0) return [];
            data[key] = newData;
        } else {
            let value = mapping[key];
            if (typeof (value) === STRING && value.indexOf("$") === 0) {
                if (value.startsWith(DOLLAR_TODAY)) {
                    let dateRegex = /^\$today(\s(?<operator>[+|-])\s(?<operand>\d+))?/;
                    let match = value.match(dateRegex);
                    if (match && match.groups) {
                        let operand = parseInt(match.groups.operand || ZERO);
                        let operator = match.groups.operator || PLUS;
                        value = new Date();
                        //assert(false, operator );
                        if (operator === PLUS) {
                            value.setDate(value.getDate() + operand);
                        } else if (operator === MINUS) {
                            value.setDate(value.getDate() - operand);
                        } else {
                            console.error('Unsupported expression ' + value);
                        }
                        value = getDateString(value);
                    }
                } else if (value.startsWith(CURRENT_USER)) {
                    let fieldName = value.split(".")[1];
                    let userDetailObj = getUserDetails();
                    let userName = '';
                    if(userDetailObj){
                        userName = userDetailObj[fieldName] ? userDetailObj[fieldName] : userDetailObj[USER_FULLNAME];
                    }  
                    value = userName;
                } else if (value.startsWith(DOCUMENT_NAME)) {
                    value = submission ? submission['formName'] : undefined;
                } else {
                    // check if mapping context takes value from an object but is not relational
                    if (value.split(".").length > 2 && !value.includes("__r")) {
                        let fieldValue:number = value.split(".").pop();
                        value = startingData[value.split(".")[1]][fieldValue];
                    } else {
                        value = startingData[value.split(".")[1]];
                    }
                }
            }
            data[key] = value;
        }
    });
};

/*
 * recursive method to transform startingData into standard format
 */
export const transformDataToClassicFormat = (startingData:any)=>{
    let transformedData = [];
    transformedData = startingData[RECORD];
    if(startingData[RELNS]){
        let relnData:any = startingData[RELNS];
        Object.keys(relnData).forEach((key)=>{
            transformedData[key] = {};
            transformedData[key].records = [];
            relnData[key].forEach((data) => {
                transformedData[key].records.push(transformDataToClassicFormat(data));
            });
        });
    }
    return transformedData;
}

/*
 * method to check submission data and map the data based on mapping in query
 */
export const mapDataToSubmission = async (submission: any) => {
    //refresh schema when user is online
    if(window.navigator.onLine){
        submission.formDef = await refreshSchema(submission.formDef);
    }

    if (submission.data != null && Object.keys(submission.data).length !== 0) {
        return submission;
    }

    if (submission.formDef){
        let data = submission.formDef.data || {};
        if(submission.formDef.startingData){
            submission.formDef.startingData.forEach((record, index)=>{
                let mapping = submission.formDef.queries[index].mappingData;
                if (mapping && record.recordTrees ) {
                    record.recordTrees.forEach( (startingDatum) => {
                        let transformedData = transformDataToClassicFormat(startingDatum);
                        fillInValues(data, transformedData, mapping, submission);
                    });
                }
            });
        }
        if(submission.formDef.hasOwnProperty('signatures')){
            if(submission.formDef.signatures.length > 0){
                if(Object.keys(submission.formDef.data).length){
                    let obj = Object.keys(submission.formDef.data);
                    data[obj[0]]["Signatures"] = submission.formDef.signatures;
                }else{
                    let obj = submission.formDef.cqFormType;
                    data[obj] = {};
                    data[obj]["Signatures"] = submission.formDef.signatures;
                }
                
            }
        }

        // Add Files into data for the selected object
        if (submission.formDef.hasOwnProperty('Files')) {
            if(submission.formDef.Files.length > 0){
                if(Object.keys(submission.formDef.data).length){
                    let obj = Object.keys(submission.formDef.data);
                    data[obj[0]]["Files"] = submission.formDef.Files;
                }else{
                    let obj = submission.formDef.cqFormType;
                    data[obj] = {};
                    data[obj]["Files"] = submission.formDef.Files;
                }
                
            }
        }
        submission.data = data;
    }
    


    return submission;
}

