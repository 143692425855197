import React, {useContext} from 'react';
import { withJsonFormsControlProps } from '@jsonforms/react';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Labels } from '@jsonforms/core/lib/util/renderer';
import AccessManagerContext from '../context/AccessManagerContext';
import strings from '../localizations/homeScreen';
import SFAPI from '../api/sfapi';
import IAccessManager from '../api/IAccessManager';
import { isSalesforceBuild } from '../salesforceBuild';
interface CQLookupProps {
    data: any;
    path: string;
    handleChange(path: string, value: any): void;
    required?: boolean;
    visible?: boolean;
    label: string | Labels;
    uischema: any;
    errors: any;
    enabled: boolean;
    schema:any
}

const CQLookupControl = ({data, handleChange, path, required, visible, label, uischema, errors, enabled, schema}: CQLookupProps) => {

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<any>([]);
  const [searchText, setSearchText] = React.useState('');
  const loading = open && options.length === 0;
  const accessManager : IAccessManager = useContext(AccessManagerContext);
  const sfAPI = new SFAPI().setAccessManager(accessManager);

  /**
   * This method helps to check if title of the schema is same of ui schema options title to work for field with more than one lookup object
   * @returns label of field 
   */
  const getLabel = () => {
    if(schema.hasOwnProperty('title') && uischema.hasOwnProperty('options')) {
      if (uischema.options.hasOwnProperty('title')) {
        if(schema['title'] !== uischema.options.title) {
          return uischema.options.title;
        }
      }
    }

    return label;
  }

  const updateOptions = async (active) => {
    const lookupOptions = await sfAPI.findRecords(searchText, uischema.lookupTo || 'Account', null, null, null);

    if (active) {
      setOptions(lookupOptions);
    }
  }

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    updateOptions(active);

    return () => {
      active = false;
    };
  }, [loading, uischema, setSearchText]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <>
    {visible?
    <div>
      <div className="slds-form-element">
        {required ? <abbr className="slds-required" title={strings.required}>* </abbr>: <></>}
        <label className="slds-form-element__label">{getLabel()}</label>
      </div>
      <Autocomplete
        disabled={!enabled || isSalesforceBuild()}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={(evt : any, value : any) => {
          handleChange(path, value.Name);
          handleChange(path + '_Id', value.Id);
        }}
        value = {data}
        freeSolo
        disableClearable
        getOptionSelected={(option : any, value : any) => option.Name === value.Name}
        getOptionLabel={(option : any) => option.Name || option}
        options={options}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={(evt : any) => {
              handleChange(path, evt.target.value);
              handleChange(path + '_Id', null);
              setSearchText(evt.target.value);
              updateOptions(true);
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        />
      {errors !== null ? <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-filled">{errors}</p> : ''}
    </div>:''}
      </>
  );
};

export default withJsonFormsControlProps(CQLookupControl);