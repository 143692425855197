import React from 'react';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { isSalesforceBuild } from '../salesforceBuild';
import Input from '@salesforce/design-system-react/components/input';
import { AccessManager } from '../api/AccessManager';

const CQDateControl = ({data, handleChange, path, required, visible, label, enabled, uischema, errors, schema}: any) => {
  
  const accessManager = new AccessManager();
  let formid = window.location.href.split('/form/')[1];

  const handleDatetimeChange=(evt:any)=>{
    data = new Date(accessManager.getDateTimeStringInUserTimeZone(evt.target.value)).toISOString();
    let localTime = new Date(evt.target.value).getTime();
    let userTime = new Date(accessManager.getDateTimeStringInUserTimeZone(evt.target.value)).getTime();
    let timeDiff = localTime - userTime;
    // this variable used to send datetime value to save it exactly what is shown in UI at field by adjusting time difference.
    let dateTimeToSave = new Date(new Date(evt.target.value).getTime() + timeDiff);
    handleChange(path, dateTimeToSave.toISOString());
  }

  /**
   * This method changes date time format according to this component needs
   * This method removes the last milliseconds portion from the standard ISO string format ending in 'Z'
  */
  const changeFormat = () => {
    const isoPattern = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})\.\d{3}Z$/;
    return  isoPattern.test(data) ?  isoPattern.exec(data)![1] : data;
  }
  

  /**
   * This method is used to change the iso datetime format to user's time zone and showing as it is.
   * On page/form load it returns time in user's time zone.
   */
  const changeToLocalFormat = () => {
    if(data){
      let dateInCurrentTimeZone = accessManager.getDateTimeStringInUserTimeZone(data);
      const offsetMs = new Date(dateInCurrentTimeZone).getTimezoneOffset() * 60 * 1000;
      const msLocal =  new Date(dateInCurrentTimeZone).getTime() - offsetMs;
      const dateLocal = new Date(msLocal);
      console.log(dateLocal);
      const iso = dateLocal.toISOString();
      const isoLocal = iso.slice(0, 19);
      return isoLocal;
    }else{
      return '';
    }
  }

	return (
    <>
    {visible?
      <Input
        type= "datetime-local"
        label ={label}
        required={required}
		    onInput={handleDatetimeChange}
        value={changeToLocalFormat()}
        errorText={errors}
        disabled={isSalesforceBuild() || !enabled}
      />:''}
  </>
  );
};

export default withJsonFormsControlProps(CQDateControl);