import React from 'react';
import logo1 from '../assets/slds/icons/utility/switch_120.png';
import logo2 from '../assets/slds/icons/utility/chevronright_120.png';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  img: {
    width: '13px',
    height: '13px',
    margin: '5px'
  },
}));


const CQSectionHeader = (props) => {


  const classes = useStyles();



  return (
    <>
      <div className="slds-accordion__summary cq-section-header"
        onClick={ () => props.toggle(props.index)}
        >
        <h2 className="slds-accordion__summary-heading">
          {props.isToggle == false ?
            <img className={classes.img} src={logo1} /> :
            <img className={classes.img} src={logo2} />
          }
          <span className="cq-section-content slds-accordion__summary-content">{props.title}</span>
        </h2>
      </div>
    </>
  )


}


export default CQSectionHeader;