import React, { useContext, useEffect, useState} from 'react';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import CQProgressBar from '../components/CQProgressBar';
import CQFileUploaderContext from '../context/CQFileUploaderContext';
import strings from '../localizations/formScreen';

import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Icon from '@salesforce/design-system-react/components/icon'; 
 

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loopButton: {
      width: '15px',
      height: '15px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      float: 'right',
      top: '3rem',
      right: '-0.5rem'
    },
    errorMessage:{
      color: "red",
      fontSize: "x-small"
    }
  }),
);




const CQFileCard = ({data, handleChange, path, required, visible, label, uischema, errors, enabled}: any) => {

  const fileUploaderContext = useContext(CQFileUploaderContext);
  const classes = useStyles();
  const [progress, setProgress] = useState(-1);
  const [errorMessage, setErrorMessage] = useState('');
  const [indeterminate, setIndeterminate] = useState(false);

  useEffect(()=> {
    if(data.progress !== undefined){
      if(data.error && data.ContentDocumentId === ''){
        data.error === 'Network Error'? setIndeterminate(true): setErrorMessage(strings.uploadFailed);
      }else if(data.error === '' && data.ContentDocumentId === ''){
        let fileInfo:any = fileUploaderContext.fileInfos.find((obj) => obj.LocalId === data.LocalId && obj.isUploading === false);
        if(fileInfo){
          if(fileInfo.ContentDocumentId !== ''){
            handleChange(path + '.ContentDocumentId' , fileInfo.ContentDocumentId);
            handleChange(path + '.error' , '');
            setErrorMessage('');
          }else{
            handleChange(path + '.error', fileInfo.error);
            setErrorMessage(strings.uploadFailed);
          }
        }else{
          setErrorMessage(strings.reUploadMessage);
        }
      }
    }
  }, []);

  useEffect(() => {
    fileUploaderContext.subscribeToProgressOf( data.LocalId, (msg) => {
      setErrorMessage('');
      setProgress(msg.progress);
      
      if(msg.status === 'uploaded'){
        handleChange(path + '.ContentDocumentId' , msg.ContentDocumentId);
        handleChange(path + '.error' , '');
        handleChange(path + '.progress' , msg.progress);
        setIndeterminate(false);
        
        setErrorMessage('');
      }else if(msg.status === 'error'){
        if(msg.errorMessage === "Network Error"){
          handleChange(path + '.error' , msg.errorMessage);
          handleChange(path + '.progress' , -1);
          setProgress(-1);
          setIndeterminate(true);
        }else{
          handleChange(path + '.error' , msg.errorMessage);
          handleChange(path + '.progress' , -1);
          setProgress(-1);
          setErrorMessage(strings.uploadFailed);
          setIndeterminate(false);
        }
      }
    });
  }, [fileUploaderContext, data])

  const handleRetry = () => {
    let fileInfo:any = fileUploaderContext.fileInfos.find((obj) => obj.LocalId === data.LocalId && obj.isUploading === false);
    if(fileInfo){
      if(fileInfo.ContentDocumentId !== ''){
        handleChange(path + '.ContentDocumentId' , fileInfo.ContentDocumentId);
        handleChange(path + '.error' , '');
        setErrorMessage('');
      }else{
        fileInfo.error = '';
        handleChange(path + '.error', '');
        fileUploaderContext.uploadFileToSF(fileInfo);
      }
    }else{
      setErrorMessage(strings.reUploadMessage);
    }
  }

  return (
    <>
    {visible?
    <IconSettings iconPath="/assets/icons"> 
      <div className="slds-grid slds-wrap">
        <div className="slds-col slds-size_1-of-1">
          <span>{data.Name}</span>
          {errorMessage !== ''? 
          <div className="slds-grid slds-wrap"> 
            <div className="slds-col slds-size_1-of-1">
              <span className={classes.errorMessage}>{errorMessage}</span>
            </div>
            <div className="slds-col slds-size_1-of-1">
              <button onClick={handleRetry}>
                <Icon 
                  assistiveText={{ label: strings.retry }}
                  category="utility"
                  name="loop"
                  size="x-small"
                />
              </button>
            </div>
          </div> : ''}
          <CQProgressBar indeterminate={indeterminate} progress={progress}/>
        </div>
      </div>
    </IconSettings>:''}
    </>
  )
};


export default withJsonFormsControlProps(CQFileCard);