import React, { useEffect, useState } from "react";
import {
  ResolvedJsonFormsDispatch,
  withJsonFormsLayoutProps,
} from "@jsonforms/react";
import IconSettings from "@salesforce/design-system-react/components/icon-settings";
import Tabs from "@salesforce/design-system-react/components/tabs";
import TabsPanel from "@salesforce/design-system-react/components/tabs/panel";

/**
 * Renderer used to render multiple tabs.
 */
const CQTabs = (props: any) => {
  const { data, uischema, schema, path, cells, renderers, enabled } = props;


  const [isToggle, setIsToggle] = useState<Boolean[]>([]);

  useEffect(() => {
    initialize();
    console.log("use effect")
  },[])

  /**
   * This method set initial values of isToggle state
   */
  const initialize = () => {
    console.log('Initialize')
    let copy : Boolean[] = [];
    setIsToggle(copy)
  }


  // used to check whether object in used as tab is addable or not
  const canAdd = (tabProperty: any): boolean => {
    if (schema?.properties) {
      let obj = schema.properties[Object.keys(schema.properties)[0]].properties;
      let childObj = obj[''+tabProperty]?.items?.properties[Object.keys(obj[''+tabProperty].items.properties)[0]]
      return childObj && !childObj.preventAddition;
    }
    return true;
  };

 // used to return length of related records
 const getRecordsLength = (childSchema: any, data: any): string =>{
    if (childSchema?.elements && childSchema?.elements[0].scope) {
      let childProps = childSchema.elements[0].scope.split('/properties/'); // split to get parent and child keys
      if(canAdd(childProps[2])){
        return (data && data[''+childProps[1]] && data[''+childProps[1]][''+childProps[2]]) ? '('+data[''+childProps[1]][''+childProps[2]].length+')' : '(0)'
      }
    }
    return '';
  }
  

  return (
    <div>
      <article className="slds-card">
        <div className="slds-p-around--large">
          <IconSettings iconPath="/assets/icons">
            <Tabs variant="scoped" id="tabs-example-scoped">
              {uischema.elements.map((child, index) => (
                <TabsPanel label={child.label +' '+ getRecordsLength(child, data)}>
                  <ResolvedJsonFormsDispatch
                    schema={schema}
                    uischema={child}
                    path={path}
                    renderers={renderers}
                    cells={cells}
                    key={index}
                    visible={true}
                    enabled={enabled}
                  />
                </TabsPanel>
              ))}
            </Tabs>
          </IconSettings>
        </div>
      </article>
    </div>
  );
};

export default withJsonFormsLayoutProps(CQTabs);
