import React, { useEffect, useState, useContext } from 'react';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { isSalesforceBuild } from '../salesforceBuild';
import Button from '@salesforce/design-system-react/components/button';
import strings from '../localizations/homeScreen';
import { composePaths } from '@jsonforms/core';
import { useJsonForms } from '@jsonforms/react';
import CQCombobox from 'components/CQCombobox';
import formStrings from '../localizations/formScreen';
import CQSubmissionContext  from '../context/CQSubmissionContext'; 
import { addCustomErrorInSubmission, removeCustomErrorInSubmission } from 'services/data-validation.service';



const CQSelectControl = ({ data, handleChange, path, required, label, visible, uischema,  errors, schema, config,enabled }: any) => {
  const COLOR_CODE = 'Color Code';
  const RESULT_TYPE_FIELD = 'compliancequest__Result_Type__c';
  const COLOR_FIELD = 'compliancequest__Color__c';

  const buttonValueField = (uischema.options && uischema.options.valueScope) || '';
  const buttonLabelField = (uischema.options && uischema.options.labelScope) || '';
  let currentValue = null;

  if(buttonValueField && data) {
    currentValue = data[buttonValueField];
  } else {
    currentValue = data;
  }

  const ctx = useJsonForms();
  const wholeDataObject : any = ctx.core?.data; // wholeDataObject stores the entire form data

  const submissionContext = useContext(CQSubmissionContext);
  const submissionInfo :any = submissionContext.submission; 
  const [errorText, setErrorText] = useState('');

  /**
   * This method used to return value by the path and using the form data
   * @param data 
   * @param path 
   * @returns value that gets by using path and data
   */
  const getValueByPath = (data, path)=> {
    const keys = path.split('.');
    let currentValue = data;
    for (const key of keys) {
      if (currentValue && typeof currentValue === 'object') {
        if (Array.isArray(currentValue)) {
          const index = parseInt(key, 10);
          currentValue = currentValue[index];
        } else {
          currentValue = currentValue[key];
        }
      } else {
        currentValue = null;
        break;
      }
    }
    return currentValue;
  }

  /**
   * This method gets value from schema or lookup cache and create a picklistvalue array in structure supported by combobox
   * @returns 
   */
  const getDropDownValues = () => {
    let picklistValues:any = {inputValue:'', selection:[]};
    if(schema.hasOwnProperty('oneOf')) {
      schema.oneOf.map((value:any, index) => {
        // for audit
        if(value.hasOwnProperty('color')) {
          let backgroundColor = value['color'].split('-')[1];
          let textColor = value['color'].split('-')[1];
          picklistValues.selection.push({"id":index,"label":value.title, "type": value.const, backgroundColor:backgroundColor, textColor:textColor});
        } else {
          // for others
          picklistValues.selection.push({"id":index,"label":value.title, "type": value.const});
        }
      })
    } else {
      getLookupOptions().map((value:any, index) => {
        picklistValues.selection.push({"id":index,"label":value.title, "type": value.const, backgroundColor:value.backgroundColor, textColor:value.textColor});
      })
    } 

    return picklistValues;
  }

  /**
   * This method checks if schema has greater than 4 values or not
   * @returns boolean value
   */
  const isOptionCountable = () => {
    if(schema.hasOwnProperty('oneOf')) {
      if(schema.oneOf.length > 4) {
        return false
      }
    } else if(getLookupOptions().length > 4) {
      return false
    } 
    return true
  }

  const getLookupOptions = () => {
    let options : any = [];

    if(config.cqconfig && config.cqconfig.lookupCache) {
      let objects = config.cqconfig.lookupCache[uischema.options.lookupTo] || [];
      let dependentOn = uischema.options.dependsOn;
      let dependentValue = (data && data[dependentOn]) || undefined;

      for(let index = 0; index < objects.length; index++) {
        let currObj = objects[index];
        if(dependentOn) {
          let value = currObj[uischema.options.field];
          if(value !== dependentValue) {
            continue;
          }


        }

        options.push({
          "const": currObj[uischema.options.valueField],
          "title": currObj[uischema.options.titleField],
          "backgroundColor": currObj[COLOR_FIELD] !== undefined ? `#${currObj[COLOR_FIELD].split("-")[1]}`: '',
          "textColor":  currObj[COLOR_FIELD] !== undefined ? `#${currObj[COLOR_FIELD].split("-")[0]}` : ''
        });
      }
    }

    return options;
  }

  const getOptions = ()  => {
    if(uischema.options && uischema.options.lookupTo) {
      return getLookupOptions();

    } else {
      return schema.oneOf || []
    }
  }


  /**
   * This holds the state for selection of value inside combobox
   */
  const[selectedOption, setSelectedOption] = useState<any>({
    inputValue: getInputValue(data),
    selection: getSelectionOption(data, buttonValueField)
  })

  useEffect(() => {
    if(data && data.length){
      setSelectedOption({
        inputValue: data,
        selection: getSelectionOption(data, buttonValueField)
      })
    }else if( schema.hasOwnProperty('dependent') && uischema.hasOwnProperty('controllingfield')){
      setSelectedOption({
        inputValue: '',
        selection: []
      })
    }
  },[data])

  /**
   * This method return backgroundColor from schema or lookupcache
   * @param value 
   * @returns 
   */
  function getBackgroundColor(value: string){
    let backgroundColor:any;
    if(value !== '') {
      getDropDownValues().selection.map(dropDownItem => {
        if(dropDownItem.label === value) {
          if(dropDownItem.hasOwnProperty('backgroundColor')) {
            backgroundColor= dropDownItem['backgroundColor']
          }
        }
      })
    }
    return backgroundColor;
  }
  
  /**
   * This method returns getInputValue
   */
  function getInputValue(data:any) {
    if(data && typeof data === 'string' && schema.hasOwnProperty('oneOf') && (JSON.stringify(schema?.oneOf).includes('color'))) {
      // for audit
      return data
    } else if (data && typeof data[buttonValueField] === 'string' ) {
      // for other objects
      return data[buttonValueField]
    }
    return ''
  }

  /**
   * This method returns for selection option
   * @param data 
   * @param buttonValueField 
   * @returns 
   */
  function getSelectionOption(data:any, buttonValueField:any) {
    if(data &&  typeof data === 'string' && schema.hasOwnProperty('oneOf')) {
      // For Audit
      let labelFromApiName = schema.oneOf.filter((element) => element.title === element.const ? element.title === data: element.const === data)[0].title; // get label from api name to show in UI
      return [{label:data[buttonValueField] ? data[buttonValueField]: labelFromApiName, type:labelFromApiName, backgroundColor:data}]
    } else if (data && typeof data[buttonValueField] === 'string' ) {
      // for other objects
      return [{label:data[buttonValueField], type:data[buttonValueField], backgroundColor:getBackgroundColor(data[buttonValueField])}]
    }
    return []
  }

  /**
   * This method updates the state when user changes the value in combobox
   * @param value 
   */
  const handleSelectedOptionChange = (value:string) => {
    let objState = {...selectedOption};
    objState.inputValue = value;
    setSelectedOption(objState);
  }

  /**
   * Method validates the entered content in combobox field and show error message
   */
  const validateAndShowError = ((value: String, optionsWithIcon: any) => {
    // check entered value in combobox search exists in options data
    const valueInOptions = optionsWithIcon.find(item => item.label === value);
    const dataPath = '/'+path.replaceAll('.','/')+'/'+uischema.options.labelScope+'/';
    const errorIndex: any = submissionInfo?.error.findIndex(obj => obj.dataPath === dataPath);
    
    let errorObj : any = {};
    // check there is value in combobox search and value doesn't exists in options 
    if(!valueInOptions && value){
      errorObj['keyword'] = 'invalid';
      errorObj['dataPath'] = dataPath;
      errorObj['message'] = formStrings.comboxErrorMessage;
      errorObj['schemaPath'] = uischema.scope;
      errorObj['schema'] = schema;
      if(errorIndex === -1){
        addCustomErrorInSubmission(submissionInfo, errorObj);
      }
      
      setErrorText(formStrings.comboxErrorMessage);
    }
  })

  /**
   * This method holds generic code for both methods
   * @returns valuefield, labelfield
   */
  const getValueandLabelField = (data : any) => {
    let valueField = composePaths(path, buttonValueField);
    let labelField = composePaths(path, buttonLabelField);
    let objState = {...selectedOption};
    objState.inputValue= '';
    objState.selection = data.selection;
    return {valueField, labelField, objState}
  }

  /**
   * This methods updates both data and state when user selects one of the option
   * @param data 
  */
 const handleSelectedOptionSelection = async(data: any) =>{
    const {valueField, labelField, objState} = getValueandLabelField(data);
    if(valueField === labelField) {
      handleChange(valueField, data.selection[0].type);
    } else {
      handleChange(valueField, data.selection[0].type);
      handleChange(labelField, data.selection[0].label);
    }
    setErrorText('');
    setSelectedOption(objState);
    const dataPath = '/'+path.replaceAll('.','/')+'/'+uischema.options.labelScope+'/';
    removeCustomErrorInSubmission(submissionInfo, dataPath);
}

/**
 * This method removes the selection and updates the state and data
 * @param data 
 */
  const handleRemoveSelection = (data: any) =>{
    const {valueField, labelField, objState} = getValueandLabelField(data);
    handleChange(valueField, data.selection);
    handleChange(labelField, data.selection);
    if(schema.hasOwnProperty('child')){
      handleChange(schema.child, '');
    }
    setSelectedOption(objState);
  }

  /**
   * This method used to return color that specified in schema for Audit checklist and Checklist Target(Audit Checklist with compliancequest__Result_Type__c as 'Color Code')
   * @param path 
   * @param option 
   * @param type 
   * @returns color that is set as background when option is selected
   */
  const getColorByResultType = (path, option, type) => {
    if(Object.keys(wholeDataObject).includes('compliancequest__SQX_Audit__c') && path.includes('compliancequest__SQX_Checklist_Targets__r')){
      const auditChecklistPath = path.split('.compliancequest__SQX_Checklist_Targets__r');
      const checklistValue : any = getValueByPath(wholeDataObject, auditChecklistPath[0]);

      //check whether compliancequest__Result_Type__c is Color Code or not in Audit Checklist
      if(checklistValue[''+RESULT_TYPE_FIELD] === COLOR_CODE && Object.keys(option).includes('color')){
        return type === 'background' ? option.color.split("-")[1]: option.color.split("-")[0]; // Return code that specified in schema
      }
    }else if(Object.keys(option).includes('color')) {
      return type === 'background' ? option.color.split("-")[1]: option.color.split("-")[0]; // return code for audit schema
    }else if(Object.keys(option).includes('backgroundColor') && type === 'background'){ // 
      return option.backgroundColor; // Return code that specified in schema
    }else if(Object.keys(option).includes('textColor') && type === 'text'){ // 
      return option.textColor; // Return code that specified in schema
    }
    return type === 'text' ? 'white' : '#0070d2'; // Return a default selected color
  };

  return (
    <>{visible?
      <div className="slds-form-element">
        {
          (uischema.options && uischema.options.showLabel) ?
            <>
              {required ? <abbr className="slds-required" title={strings.required}>* </abbr>: <></>}
              <label className="slds-form-element__label">{label}</label>
            </>
            :
            <></>
        }
        <div className="slds-form-element__control">

						{isOptionCountable()?(getOptions().map((option: any) => (
                <span key={option.const} className="slds-m-right_medium">
                  <Button
                    className="cq-select-btn"
                    label={option.title}
                    variant={option.const === currentValue ? 'brand':  'neutral'}
                    onClick={(evt : any) => {
                      // let selected = option.const === currentValue;
                      if(enabled){
                        let newValue = option.const;
                        if(buttonValueField && buttonLabelField) {
                          let valueField = composePaths(path, buttonValueField);
                          let labelField = composePaths(path, buttonLabelField);
                          handleChange(valueField, newValue);
                          handleChange(labelField, option.title);
                        } else {
                          handleChange(path, newValue);
                        }
                      }
                    }}
                    disabled={isSalesforceBuild() || !enabled }
                    style={{
                      color:option.const === currentValue ? getColorByResultType(path, option, 'text'): '#0070d2',
                      backgroundColor: option.const === currentValue ? 
                        getColorByResultType(path, option, 'background')
                        : 'white'             
                    }}
                  />
                </span>
						))):
            <CQCombobox 
            selectedOption={selectedOption} 
            options={getDropDownValues()} 
            disabled={isSalesforceBuild() || !enabled }
            handleSelectedOptionChange={handleSelectedOptionChange}
            handleSelectedOptionSelection={handleSelectedOptionSelection}
            handleRemoveSelection={handleRemoveSelection}
            validateAndShowError={validateAndShowError}
            error={errorText}
          />}
        </div>
      </div>
      :''}
      </>
  );
};

export default withJsonFormsControlProps(CQSelectControl);