import React from 'react';
import { withJsonFormsControlProps } from '@jsonforms/react';
import Textarea from '@salesforce/design-system-react/components/textarea';
import { isSalesforceBuild } from '../salesforceBuild';


const CQTextAreaControl = ({data, handleChange, path, required, visible, label, enabled, uischema, errors, schema}: any) => {

  const handleTextareaChange = (evt : any) => {
    data = evt.target.value
    handleChange(path, data);
  }

  return (
    <>{visible?
      <Textarea
        className="cq-textarea"
				label={label}
        errorText={errors}
        required={required}
        onChange={handleTextareaChange}
        value={data}
        disabled={isSalesforceBuild() || !enabled}
      />:''}
		</>
  );
};

export default withJsonFormsControlProps(CQTextAreaControl);