import App from './App';
import CQForms from './components/CQForms';
import CQForm from './components/CQForm';
import CQFormsLogin from './components/CQFormsLogin';
import CQSFAuthSuccess from './components/sfAuthSuccess/sfAuthSuccess'
import CQLaunchFromSF from './components/CQLaunchFromSF';
import CQEditFormSF from './components/editor/CQEditFormSF';

const routes = [
    {
      component: App,
      routes: [
        {
          path: "/",
          exact: true,
          component: CQForms
        },
        {
          path: "/login",
          exact: true,
          component: CQFormsLogin 
        },
        {
          path: "/launchFromSF/:formId?/:formName?/:formSubmissionId?/:formSubmissionStatus?/:submissionId?",
          exact: true,
          component: CQLaunchFromSF
        },
        {
          path: "/form/:formid",
          component: CQForm
        },
        {
          path: "/authSuccessCallback",
          exact: true,
          component: CQSFAuthSuccess
        },
        {
          path: "/CQEditFormSF/:editFormRecordId",
          exact: true,
          component: CQEditFormSF
        }
      ]
    }
  ];

export default routes;