import React from "react";
import { withJsonFormsControlProps } from "@jsonforms/react";
import Checkbox from "@salesforce/design-system-react/components/checkbox";
import { isSalesforceBuild } from '../salesforceBuild';

const CQCheckboxControl = ({data,handleChange,path,required,visible, label,enabled,uischema,errors,schema}: any) => {

  
  const handleCheckboxChange = (evt:any)=>{
    data = evt.target.checked;
    handleChange(path,data);
  }

  let customCheckboxLabel = {'label' : label};
  return (
    <>
    {visible?
      <Checkbox
        labels= {customCheckboxLabel}
        onChange={handleCheckboxChange}
        required={required}
        checked={data}
        errorText={errors}
        disabled={isSalesforceBuild() || !enabled}
      />:''}
    </>
  );
};

export default withJsonFormsControlProps(CQCheckboxControl);