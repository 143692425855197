import Dexie from 'dexie';

const db = new Dexie("ImageDatabase");

db.version(1).stores({
    "images": "id, ContentDocumentId, submissionId",
    "guides": "Id, ImageUrl"
});

/**
 * This method store guide images in db
 * @param guide : guide images
 * @returns 
 */
export const storeGuideImageInDB = async (guide) => {
    try {
        await db["guides"].add(guide);
    } catch (ex) {
        console.error(ex);
        return false;
    }
    return true;
}

/**
 * This method return encoded image 
 * @param imageUrl : guide image url
 * @returns 
 */
export const getGuideImage = async (imageUrl: string) => {
    try {
        let ret = await db['guides'].get({ ImageUrl: imageUrl });
        return ret.encodedImage
    } catch (ex) {
        console.log(ex);
        return [];
    }
}

/**
 * delete cached guide images
 * @param Id form Id 
 * @returns 
 */
export const deleteGuideImageFromDB = async (Id: string) => {
    // @ts-ignore
    try {
        await db['guides'].delete(Id)
    } catch (ex) {
        console.log(ex);
        return false;
    }
    return true;
};


/*
 * method to store file info in db
 */
export const storeInDB = (id : string, fileData : File, path: string, fileName: string, submissionId: string, organizationId: string,  ContentDocumentId : String = 'Unsynced') : Promise<boolean> => {
    let promise : Promise<boolean> = new Promise(async (resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(fileData);
        reader.onload = () => {
            //resolve(reader.result)
            try{
                let result = reader.result;
                //@ts-ignore
                db['images'].add({id, result, path, fileName, submissionId, organizationId, ContentDocumentId}).then(() => {
                    resolve(true);
                }).catch((ex) => {
                    console.log(ex);
                    resolve(false);
                })
            }catch(ex){
                console.log(ex);
                resolve(false);
            }
        };
        reader.onerror = () => reject(false);

        
    });

    return promise;
}

/*
 * method to get ContentDocumentId from db for given id
 */
export const getContentId = async (id: string) => {

    // @ts-ignore
    try{
        let ret = await db['images'].get({'id': id});
        let val = ret && ret.ContentDocumentId !== 'Unsynced' ? ret.ContentDocumentId : null;
        return val;
    }catch(ex){
        console.log(ex);
        return null;
    }
}

/*
 * get content from db for given id
 */
export const getContent = async (id: string)  => {
    // @ts-ignore
    try{
        let img = await db['images'].get({'id': id})
        return img;
    }catch(ex){
        console.log(ex);
        return null;
    }
}

/*
 * method to update db for given id with contentdoucmentid
 */
export const updateDBItem = async (id: string, ContentDocumentId : string) => {
    // @ts-ignore
    try{
        await db['images'].update({id, ContentDocumentId : "Unsynced"}, {ContentDocumentId: ContentDocumentId, result: null});
    }catch(ex){
        console.log(ex);
        return false;
    }
    return true;
};

/*
 * delete content of db for given id
 */
export const deleteDBItem = async (id: string)  => {
    // @ts-ignore
    try{
        await db['images'].delete(id);
    }catch(ex){
        console.log(ex);
        return false;
    }
    return true;
};

/*
 * delete content of db for given submission id
 */
export const deleteDBImagesForSubmission = async (submissionId: string)  => {
    // @ts-ignore
    try{
        let images = await db['images'].where({submissionId: submissionId}).toArray();
        if(images && images.length> 0){
            let ids = images.map((img)=> { return img.id});
            await db['images'].bulkDelete(ids);
        }
    }catch(ex){
        console.log(ex);
        return false;
    }
    return true;
};

/*
 * method for returning one unsynced content from db 
 */
export const getUnsycnedFileItemInDB = async ()  => {
    
    //@ts-ignore
    try{
        let unsyncedImage = await db['images'].get({'ContentDocumentId': 'Unsynced'});
        return unsyncedImage;
    }catch(ex){
        console.log(ex);
        return null;
    }

    
}

/*
 * method to return all contents from db
 */
export const getFilesItemInDB = async () => {
    try{
        //@ts-ignore
        let filesInDB = await db['images'].toArray();
        return filesInDB;
    }catch(ex){
        console.log(ex);
        return [];
    }
}