import LocalizedStrings from 'react-localization';


let strings = new LocalizedStrings({
    en: {
        assigned: 'Assigned to me',
        inProgress: 'In Progress',
        forms: 'Forms',
        submitted: 'Submitted',
        NoData: 'No records were found',
        launch: 'Launch',
        checklist: 'Checklist',
        parentRecord: 'Parent Record',
        target: 'Target',
        dueDate: 'Due Date',
        status: 'Status',
        synced: 'Synced',
        scheduledFor: 'Scheduled For',
        subject: 'Subject',
        safetyInspectionTitle: 'Safety Inspection Title',
        startDate : 'Start Date',
        endDate : 'End Date',
        actions: 'Actions',
        offline: 'Offline',
        search: 'Search',
        required: 'Required',
        checklists: 'Checklists',
        formsTitle: 'ComplianceQuest Forms',
        loginProduction: 'Login Production',
        loginSandbox: 'Login Sandbox',
        loginCustom: 'Login with Custom Domain',
        documentTitle: '{name} : {title}',
        placeHolderCustomDomain: 'Enter Custom Domain URL here',
        logOut: 'Log Out',
        avatarImage: 'Avatar Image',
        schemaError: 'Schema error in server',
        serverError: 'Server Error: ',
        mainFrameLoading: 'Main Frame Loading...',
        logoAltText: 'Form Logo',
        networkError: 'Error: Cannot connect to Internet',
        logoutConfirm: 'Are you sure to Logout ?',
        starting: 'Pending...',
        start: 'Start',
        deleteCache: 'Are you sure want to remove it from offline Mode?',
        checklistName: 'Checklist Name',
        submissionError: 'Errors were found and could  not Submit. Please Launch to see errors and try to submit again',
        offlineFormSubmit: 'Form has been sent for submission',
        formSubmissionMessage: 'These are the forms that are submitted but not processed. Forms will be auto processed as soon as you are back online'
    }
});

export default strings;