

/**
 * @export
 * @class CQAppConstant
 */
// const ENV_VARIABLE =  process.env;

// Connected app properties to be used while authenticating user with salesforce - SANDBOX
export const ENV_SANDBOX = {
    production: false,
    CLIENT_ID: '3MVG9FxR3Tq3eZN9KDh6sgdei4WHb8XyGTwqt3LdplqgSRqY4.UD538DyzzAo0bjeaJ8EtKDNaN7xMhl40f_d',
    REDIRECT_URI: 'https://cq-forms-dev.herokuapp.com/authSuccessCallback',
    AUTH_ENDPOINT: 'https://test.salesforce.com',
    RESPONSE_TYPE: 'token',
    // CQ Lightning Backend 
    NODE_END_POINT: 'https://cq-forms-dev.herokuapp.com'
  };
  
  

export class CQAppConstant {



    public static AUTH_REQUEST_URL_SANDBOX =
        `${'https://test.salesforce.com'}?client_id=
        ${'3MVG9FxR3Tq3eZN9KDh6sgdei4WHb8XyGTwqt3LdplqgSRqY4.UD538DyzzAo0bjeaJ8EtKDNaN7xMhl40f_d'}&response_type=${process.env.REACT_APP_SANDBOX_RESPONSE_TYPE}&redirect_uri=
        ${'https://cq-forms-dev.herokuapp.com/authSuccessCallback'}`;

    public static AUTH_REQUEST_URL_PRODUCTION =
        `${process.env.REACT_APP_PRODUCTION_AUTH_ENDPOINT}?client_id=
        ${process.env.REACT_APP_PRODUCTION_CLIENT_ID}&response_type=${process.env.REACT_APP_PRODUCTION_RESPONSE_TYPE}&redirect_uri=
        ${process.env.REACT_APP_PRODUCTION_REDIRECT_URI}`;

    public static SF_OAUTH_REQUEST_SANDBOX = {
        SF_OAUTH_LOGIN_URL: 'https://test.salesforce.com',
        SF_CLIENT_ID: '3MVG9FxR3Tq3eZN9KDh6sgdei4WHb8XyGTwqt3LdplqgSRqY4.UD538DyzzAo0bjeaJ8EtKDNaN7xMhl40f_d',
        SF_CLIENT_SECRET: process.env.REACT_APP_SANDBOX_CLIENT_SECRET,
        SF_REDIRECT_URL: 'https://cq-forms-dev.herokuapp.com/authSuccessCallback'
    };

    public static SF_OTHER_ORG_OAUTH_REQUEST_SANDBOX = {
        SF_OAUTH_LOGIN_URL: 'https://test.salesforce.com',
        SF_CLIENT_ID: '3MVG9FxR3Tq3eZN9KDh6sgdei4WHb8XyGTwqt3LdplqgSRqY4.UD538DyzzAo0bjeaJ8EtKDNaN7xMhl40f_d',
        SF_CLIENT_SECRET: process.env.REACT_APP_SANDBOX_CLIENT_SECRET,
        SF_REDIRECT_URL: process.env.REACT_APP_OTHER_ORG_SANDBOX_REDIRECT_URI
    };

    public static SF_OAUTH_REQUEST_PROD = {
        SF_OAUTH_LOGIN_URL: process.env.REACT_APP_PRODUCTION_AUTH_ENDPOINT,
        SF_CLIENT_ID: process.env.REACT_APP_PRODUCTION_CLIENT_ID,
        SF_CLIENT_SECRET: process.env.REACT_APP_PRODUCTION_CLIENT_SECRET,
        SF_REDIRECT_URL: process.env.REACT_APP_OTHER_ORG_RODUCTION_REDIRECT_URI
    };

    public static SF_OTHER_ORG_OAUTH_REQUEST_PROD = {
        SF_OAUTH_LOGIN_URL: process.env.REACT_APP_PRODUCTION_AUTH_ENDPOINT,
        SF_CLIENT_ID: process.env.REACT_APP_PRODUCTION_CLIENT_ID,
        SF_CLIENT_SECRET: process.env.REACT_APP_PRODUCTION_CLIENT_SECRET,
        SF_REDIRECT_URL: process.env.REACT_APP_OTHER_ORG_RODUCTION_REDIRECT_URI
    };

    public static NODE_SERVER_BASE = process.env.REACT_APP_PRODUCTION_NODE_ENDPOINT;

    public static CQ_ERROR_TYPES_TEXT = 'CQ_ERROR_TYPES';
    public static ERROR_GENERIC = 'ERROR_GENERIC';
    public static ERROR_FATAL = 'ERROR_FATAL';
    public static ERROR_SERVER = 'ERROR_SERVER';
    public static ERROR_CUSTOM = 'ERROR_CUSTOM';
    public static ERROR_SESSION = 'ERROR_SESSION_EXPIRED';
    public static DEPLOY_FAILED = 'ERROR_DEPLOY_FAILED';
    public static UNKNOWN_PUBLISH_ERROR = 'UNKNOWN_PUBLISH_ERROR';
    public static BASE_CLASS_ALREADY_EXIST_WARNING = 'BASE CLASS ALREADY EXIST';
    public static COMPONENT_NAME_ALREADY_EXIST_WARNING = 'COMPONENT NAME ALREADY EXIST';
    public static SAME_BASE_CLASS_ERROR = 'SAME BASE CLASS ERROR';

    public static CQ_ERROR_TYPES = [
        CQAppConstant.ERROR_GENERIC,
        CQAppConstant.ERROR_FATAL,
        CQAppConstant.ERROR_SERVER,
        CQAppConstant.ERROR_CUSTOM,
        CQAppConstant.ERROR_SESSION,
        CQAppConstant.DEPLOY_FAILED,
        CQAppConstant.UNKNOWN_PUBLISH_ERROR,
        CQAppConstant.BASE_CLASS_ALREADY_EXIST_WARNING,
        CQAppConstant.COMPONENT_NAME_ALREADY_EXIST_WARNING,
        CQAppConstant.SAME_BASE_CLASS_ERROR
    ];

    public static API_RESPONSE_CODES = {
        UNAUTHORIZED: 401,
        SUCCESS: 200,
        BAD_REQUEST: 400,
        FORBIDDEN_REQUEST: 403,
        INTERNAL_SERVER_ERROR: 500
    };

    public static ERROR_MESSAGES = {
        SESSION_EXPIRED: 'Your session has expired. Any changes done will be lost.',
        UNKNOWN_ERROR: 'Something went wrong. Please try again.',
        PACKAGE_DEPLOYMENT_FAILURE: 'Package deployment failed',
        CORS_ERROR_MESSAGE:'Can not retrieve deployment status,but deployment might have been succeeded in the background.',
        BASE_CLASS_ALREADY_EXIST_WARNING_MESSAGE: 'The generated component has the same name as a base class name, confirm to overwrite the base class component?',
        COMPONENT_NAME_ALREADY_EXIST_WARNING_MESSAGE: 'The generated component already exist in org, confirm to override the component in org?',
        SAME_BASE_CLASS_ERROR_MESSAGE: 'The generated component name is the same as the base class name, please check parameters'
    };

    public static HTTP_REQUEST_TYPES = {
        GET_REQUEST: 'GET',
        POST_REQUEST: 'POST',
        DELETE_REQUEST: 'DELETE',
    };

    public static LOADING_TEXT_DEFAULT = 'Fetching Data';

    public static JWT_KEY = 'jwtToken';
    public static INSTANCE_URL = 'instanceUrl';
    public static VERSION_NUMBER = 'versionNumber';
    public static LOGGED_IN_USERNAME = 'username';
    public static LOGIN_URL = 'loginUrl';
    public static SANDBOX_ENV = 'test';
    public static PRODUCTION_ENV = 'prod';
    public static SANDBOX_LOGINURL = 'https://test.salesforce.com';
    public static PRODUCTION_LOGINURL = 'https://login.salesforce.com';

    public static NAVIGATION = {
        DEFAULT_PATH: '/',
        LOGIN: '/login',
        AUTH_SUCCESS_CALLBACK: '/authSuccessCallback',
        OTHER_ORG_AUTH_SUCCESS_CALLBACK: '/authOtherOrgSuccessCallback',
        TEMPLATES: '/templates',
        TEMPLATES_CONFIGURATION: '/templateConfiguration',
    };

    public static TEMPLATE_CONFIG_TABS = {
        COMPONENT_CONFIG_TAB: 'Component level config',
        LAYOUT_CONFIG_TAB: 'Layout level config',
    };

    public static ACTION_OVERRIDE = 'actionOverride';
    public static ICON_NAME = 'iconName';
    public static ACTION_OVERRIDE_DROPDOWN = ['Create', 'Edit', 'None'];
    public static NONE = 'None';
    public static CLASS_NAME = 'className';
    public static BOOLEAN = 'boolean'
    public static DOWNLOAD = 'Download';
    public static PUBLISH = 'Publish';
    public static DOWNLOADABLE_ZIP_FILE_NAME = 'src.zip';
    public static AND_OPERATOR = 'AND';
    public static OR_OPERATOR = 'OR';
    public static ADD_COMPLEX_LOGIC = 'Add Complex logic';
    public static PICKLIST = 'Picklist';
    public static COMPLEX_LOGIC_QUERY = 'complexLogicQuery';
    public static CONFIRM_MESSAGE_LAYOUT_CHANGE = 'Warning: Changing layout or object will reset the rules defined in the editor. Do you want to continue?';


    public static ADDITIONAL_FIELDS_ACTIONS = {
        ADD: 'add',
        UPDATE: 'update',
        DELETE: 'delete',
    };

    public static LOGICAL_OPERATORS = {
        AND: '&&',
        OR: '||'
    };


    public static CQ_RECORD_RULES_TYPES = {
        hidden: { key: 'hidden', label: 'Hide field'},
        readonly: { key: 'readonly', label: 'Make field read-only'},
        required: { key: 'required', label: 'Make field required'},
        redirectTo: { key: 'redirectTo', label: 'Set filter and query for redirection'},
        visible: { key: 'visible', label: 'Show field'},
        lookupFilter: { key: 'lookupFilter', label: 'Lookup Filter'},
        dynamicSource: { key: 'dynamicSource', label: 'Get lookup options from apex'},
        formulaFieldHyperlink: { key: 'Formula Field - Hyperlink', label: 'Evaluate formula'},
        formulaFieldImage: { key: 'Formula Field - Image', label: 'Evaluate formula'},
        transfer: { key: 'transfer', label: 'Transfer parent field value to field'},
        setValues: { key: 'setValues', label: 'Set the value of a particular field'},
        invoke: { key: 'invoke', label: 'Invoke a flow that modifies field value'}
    };

    public static OBJECT_NAME = 'objectName';
    public static LOOKUP_FILTERS = 'lookupFilter';
    public static DYNAMIC_SOURCE = 'dynamicSource';
    public static TRANSFER_RULE = 'transfer';

    public static TOGGLE_ACTION_RULES = [
        'hidden',
        'readonly',
        'visible',
        'required',
    ];

    public static COMPONENT_EVENT_RULES_MAP :any = {
        afterSaveRedirectTo: { key: 'afterSave-redirectTo', label: '' },
        onInitInvoke: { key: 'onInit-invoke', label: '' },
        onLoadInvoke: { key: 'onLoad-invoke', label: '' },
        onLoadsetValues: { key: 'onLoad-setValues', label: '' },
        onLoadtransfer: { key: 'onLoad-transfer', label: '' }
    };

    public static LAYOUT_EVENT_RULES :any = {
        afterSave: { key: 'afterSave', label: 'After saving redirect to' },
        onInit: { key: 'onInit', label: 'When creating a new record'},
        onLoad: { key: 'onLoad', label: 'When page loads' }
    };

    public static LAYOUT_EVENT_SUB_RULE = {
        afterSave: [
            'redirectTo'
        ],
        onInit: [
            'invoke'
        ],
        onLoad: [
            'invoke',
            'setValues',
            'transfer'
        ]
    };

    public static TOGGLE_ACTION_RULE_TYPES = [
        'hidden',
        'readonly',
        'visible',
        'required',
    ];

    public static TRANSFER_RULE_TYPE = 'transfer';
    public static ACTION = 'action';

    public static ADDED_CRITERIAS = 'addedCriterias';
    public static ISVISIBLE = 'isVisible';
    public static LOOKUP_FILTER = 'filters';
    public static LOOKUP_FILTER_SELECTED_REFERENCE_FIELDS = 'selectedReferenceFields';
    public static SET_OBJECTS = 'setObject';
    public static SET_VALUES = 'setValues';
    public static COMPLEX_LOGIC = 'complexLogic';
    public static ADD_TRIGERRING_FIELDS = 'Add triggering fields';
    public static TRIGERRING_FIELD = 'Triggering field';
    public static PARENT_FIELD_VALUE = '.value';
    public static DEFAULT_PARENT_FIELDS = ['Id' , 'Name'];

    public static PUBLISH_STATUS_URL = '/lightning/setup/DeployStatus/home';


    public static INPUT_RULE_OPERATORS = [
        {
            label: 'Exists',
            value: 'Exists'
        },
        {
            label: 'Is Empty',
            value: 'IsEmpty'
        },
        {
            label: 'Equal to',
            value: '=='
        },
        {
            label: 'Not Equal to',
            value: '!='
        },
        {
            label: 'Greater than equal to',
            value: '>='
        },
        {
            label: 'Greater than',
            value: '>'
        },
        {
            label: 'Less than Equal to',
            value: '<='
        },
        {
            label: 'Less than',
            value: '<'
        }
    ];

    public static EXISTS = 'Exists';
    public static ISEMPTY = 'IsEmpty';
    public static RECORD = 'record.';
    public static PARENT = 'parent.';

    public static LOOKFILTER_CONDITION_OPERATORS = [
        {
            label: 'All Conditions Are Met',
            value: 'and'
        },
        {
            label: 'Any Condition Is Met',
            value: 'or'
        }
    ];

    public static LOOKFILTER_FIELD_OPERATORS = [
        {
            label: 'eq',
            value: 'eq'
        },
        {
            label: 'neq',
            value: 'neq'
        },
        {
            label: 'lt',
            value: 'lt'
        },
        {
            label: 'lte',
            value: 'lte'
        },
        {
            label: 'gt',
            value: 'gt'
        },
        {
            label: 'gte',
            value: 'gte'
        },
        {
            label: 'endswith',
            value: 'endswith'
        },
        {
            label: 'startswith',
            value: 'startswith'
        },
        {
            label: 'contains',
            value: 'contains'
        }
    ];

    public static GRID_AND_CLAUSE = [
        {
            label: 'eq',
            value: '='
        },
        {
            label: 'neq',
            value: '!='
        },
        {
            label: 'lt',
            value: '<'
        },
        {
            label: 'lte',
            value: '<='
        },
        {
            label: 'gt',
            value: '>'
        },
        {
            label: 'like',
            value: 'LIKE'
        },
        {
            label: 'in',
            value: 'IN'
        },
        {
            label: 'not-in',
            value: 'NOT IN'
        }
    ];

    public static LOOKUPFILTER_STATIC_VALUE_TYPE_MAP = [
        {
            type: 'string', keyValue: 's_value'
        },
        {
            type: 'integer', keyValue: 'i_value'
        },
        {
            type: 'double', keyValue: 'd_value'
        },
        {
            type: 'boolean', keyValue: 'b_value'
        },
        {
            type: 'date', keyValue: 'dt_value'
        },
        {
            type: 'datetime', keyValue: 'dtt_value'
        },
        {
            type: 'reference', keyValue: 'usv_param'
        }
    ];

    public static SETVALUE_RULE_DYNAMIC_SOURCE_VALUES = [
        {
            label: 'Clear value',
            value: null,
            isSupportedFunction: false
        },
        {
            label: 'AddDays()',
            value: 'addDays',
            isSupportedFunction: true
        },
        {
            label: 'setCurrentUser()',
            value: 'setCurrentUser',
            isSupportedFunction: true
        }
    ];

    public static RULE_TYPES_OBJECT: any = {
        LOOKUP: [
            'hidden',
            'readonly',
            'visible',
            'required',
            'lookupFilter',
            'dynamicSource',
            'transfer',
            'setValues',
            'invoke'
        ],
        TEXT: [
            'hidden',
            'readonly',
            'visible',
            'required',
            'setValues',
            'invoke'
        ],
        PICKLIST: [
            'hidden',
            'readonly',
            'visible',
            'required',
            'setValues',
            'invoke'
        ],
        FORMULA_FIELD: [
            'hidden',
            'readonly',
            'visible',
            'required',
            'formulaFieldHyperlink',
            'formulaFieldImage'
        ],
        DEFAULT: [
            'hidden',
            'readonly',
            'visible',
            'required',
            'setValues',
            'invoke'
        ]
    };
    
    public static RECORD_TYPE_ID_FIELD_METADATA = {
        RecordTypeId: {
            fullName: 'RecordTypeId',
            label: 'Record Type ID',
            required: 'false',
            type: 'Lookup',
            referenceTo : 'RecordType',
            relationshipName: 'RecordType',
            relationshipLabel: 'RecordType'
        }
    };

    public static NAME_FIELD_METADATA = {
        Name: {
            fullName: 'Name',
            label: '',
            required: 'false',
            type: 'Text',
            relationshipName: '',
            relationshipLabel: ''
        }
    };

    public static ACTION_TYPES = [
        'standard_header_action',
        'custom_header_action',
        'standard_row_action',
        'custom_row_action'
    ];

    public static EVENT_LISTENER = {
        CQ_UPDATE_INDEX: 'cqupdateindex'
    }

    public static GENERAL = 'General';

    public static API_VERSION = 'v52.0';
}
