import LocalizedStrings from 'react-localization';


let strings = new LocalizedStrings({
  en: {
    cqFormBuilder: 'CQ Form Builder',
    saveForm : 'Save Form',
    editLogo : 'Edit Logo',
    addLogo : 'Add Logo',
    addSignature: 'Add Signature',
    fieldSearch: 'Search for a field or document',
    infoLabel: 'info',
    deleteLabel: 'delete',
    readOnlyLabel: 'Read Only',
    dragLabel: 'drag',
    requiredLabel: 'Required',
    generalSection: 'Unmapped Fields',
    documentsSection: 'Documents',
    fieldsSection: 'Mapped Fields',
    relatedFieldsSection: 'Related Context Fields',
    signature: 'Signature',
    save: 'Save',
    cancel: 'Cancel',
    signaturePlaceholder: 'Enter text',
    logoTypeError: 'Logo should be jpg or png type',
    fieldLabel: 'Field Label',
    generalFieldPlaceholder: 'TextArea Text',
    picklistValues: 'Picklist Values',
    add: 'Add',
    picklistPlaceholder: 'Enter picklist value',
    successMessage: 'Changes Saved Successfully.',
    documentErrorMessage: 'Document Criteria is not setup correctly.',
    addFileAttachment: 'Add File Attachment',
    selectObject: 'Select Objects',
    mappedFieldObject: 'Mapped Field Object',
    contextFieldObject: 'Context Field Object',
    searchObjectPlaceholder: 'Search Salesforce',
    selectObjectNext: 'Next',
    contextHelpText:'This object is a related object to your Mapped Field Object. These fields will be readonly and will help provide context for your form.',
    mainObjectHelpText: 'This is the object that your form will be related to. The fields on this object you will be able to map back into the system.'
  }
});

export default strings;