import React, { useRef, useState } from 'react';
import strings from '../../localizations/homeScreen';
import Button from '@salesforce/design-system-react/components/button';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Icon from '@salesforce/design-system-react/components/icon'; 
import moment from 'moment';
import { useHistory } from "react-router-dom";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { AccessManager } from '../../api/AccessManager';
import CQInProgressTab from '../CQInProgressTab';
import CQToast from '../CQToast/CQToast';
import { CQApiConstant } from '../../api/api-constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    Title: {
      color: '#0070D2',
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "1rem"
    }
  }),
);


function CQInProgressList(props) {
  const [showToast, setShowToast] = useState(false);
  let history = useHistory();
  const classes = useStyles();

  const accessManager = new AccessManager();

  /**
   * Initialize a timecount variable and handle the click event for warning icon
   */
  const timeCount = useRef(10000);
  const handleClick = () => {
    setShowToast(true);

    setTimeout(() => {
      setShowToast(false);
    }, timeCount.current)
  }

  return (

    <React.Fragment>
      <div className="slds-box slds-grid slds-m-top_x-small slds-text-body_regular slds-m-horizontal_medium">
        <div className="slds-col slds-grid slds-size_10-of-12 slds-wrap">
              {props && props.item?.errors?.length > 0  || props.item?.error?.length > 0 ?
                <span onClick={handleClick} className='slds-m-horizontal_x-small'>
                  <IconSettings iconPath="/assets/icons">
                      <Icon
                        className="warning slds-icon-text-warning"
                        category="utility"
                        name="warning"
                        size="medium"
                      />
                  </IconSettings>
                </span>: ""
              }
          <CQInProgressTab item={props.item} totalItems = {4} />
        </div>
        <div className="slds-col slds-size_2-of-12" style={{marginTop: "-0.125rem"}}>
          <p style={{ color: "grey" }} className="slds-m-bottom_xx-small  slds-align_absolute-center">{strings.actions}</p>
          <Button className="slds-size_10-of-12 slds-m-horizontal_small" onClick={() => history.push(`/form/${props.item.id}`)}>
            {strings.launch}
          </Button>
        </div>
      </div>
      {showToast ?
        <CQToast 
          variant="error"
          heading={strings.submissionError}
          duration={CQApiConstant.TOAST_TIMER}
        />: ""}
    </React.Fragment>
  );
}

export default CQInProgressList;
