import React from 'react';
import strings from '../../localizations/homeScreen';
import Button from '@salesforce/design-system-react/components/button';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    Title: {
      color: '#0070D2'
    }
  }),
);


function CQAssignedList(props) {
  const classes = useStyles();

  let instanceUrl = localStorage.getItem('instanceurl');
  return (

    <React.Fragment>
      
      <div className="slds-box slds-grid slds-m-top_x-small">
        <div className="slds-col slds-size_5-of-12">
          <div className={classes.Title}>
            <a href={instanceUrl + '/'+ props.item.taskId} target="_blank">{props.item.checklist}</a>
          </div>
        </div>
        <div className="slds-col slds-size_5-of-12">
          <a href={instanceUrl + '/'+ props.item.taskId} target="_blank">{props.item.subject}</a>
        </div>
        <div className="slds-col slds-size_2-of-12">
          <a href={instanceUrl + '/'+ props.item.taskId} target="_blank">{props.item.scheduledFor}</a>
        </div>
       {/*  onClick={() => props.startSubmission(props.item)} */}
      </div>
    </React.Fragment>
  )
}

export default CQAssignedList;
