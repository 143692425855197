import React from "react";
import { withJsonFormsControlProps } from "@jsonforms/react";
import RadioGroup from "@salesforce/design-system-react/components/radio-group";
import Radio from "@salesforce/design-system-react/components/radio";
import IconSettings from "@salesforce/design-system-react/components/icon-settings";
import { isSalesforceBuild } from "../salesforceBuild";
import { composePaths } from "@jsonforms/core";

const CQRadioControl = ({
  data,
  handleChange,
  path,
  required,
  label,
  visible,
  schema,
  enabled,
  config,
  uischema,
}: any) => {
  const buttonValueField = (uischema.options && uischema.options.valueScope) || "";
  const buttonLabelField = (uischema.options && uischema.options.labelScope) || "";

  let currentValue: string = "";

  if (buttonValueField && data) {
    currentValue = data[buttonValueField];
  } else {
    currentValue = data;
  }

  /**
   * This will provide options for fields
   * @returns array of options
   */
  const radioOptions = () => {
    let radioOptions = [];
    if (schema.hasOwnProperty("oneOf")) {
      radioOptions = schema.oneOf;
    }
    return radioOptions;
  };

  /**
   * This will provide options for checklist
   * @returns array of options
   */
  const configOptions = () => {
    let options: any = [];

    if (config.cqconfig && config.cqconfig.lookupCache) {
      let objects = config.cqconfig.lookupCache[uischema.options.lookupTo] || [];
      let dependentOn = uischema.options.dependsOn;
      let dependentValue = (data && data[dependentOn]) || undefined;

      for (let index = 0; index < objects.length; index++) {
        let currObj = objects[index];
        if (dependentOn) {
          let value = currObj[uischema.options.field];
          if (value !== dependentValue) {
            continue;
          }
        }

        options.push({
          const: currObj[uischema.options.valueField],
          title: currObj[uischema.options.titleField],
        });
      }
    }

    return options;
  };

  const isLookupField = () => {
    return uischema.options && uischema.options.lookupTo;
  };

  const getOptions = () => {
    if (isLookupField()) {
      return configOptions();
    }
    return radioOptions();
  };

  /**
   * This method returns Radio Group element to load it dynamically for fields and checklist
   * @param children 
   * @param option 
   * @returns Html Element
   */
  const radioGroup = (children: any, option?: any) => {
    return (
      <RadioGroup
        labels={{ label: isLookupField() ? "" : label }}
        disabled={isSalesforceBuild() || !enabled}
        onChange={(event) => {
          if (enabled) {
            let newValue = option ? option.const: event.target.id;
            let valueField = composePaths(path, buttonValueField);
            let labelField = composePaths(path, buttonLabelField);
            if (buttonValueField && buttonLabelField) {
              handleChange(valueField, newValue);
              handleChange(labelField, option.title);
            } else {
              handleChange(path, newValue);
            }
          }
        }}
        required={required}
        className={isLookupField() ? "cq-radio-checklist" : "cq-radio"}
      >
        {children}
      </RadioGroup>
    );
  };

  /**
   * This method returns Radio element to load it dynamically for fields and checklist
   * @param option 
   * @returns Html Element
   */
  const radioElement = (option) => {
    let valueField = composePaths(path, buttonValueField);
    return (
      <Radio
        key={option.const}
        className={
          isLookupField()
            ? "cq-checklist-radio-button"
            : "slds-grid slds-m-around_xx-small"
        }
        id={isLookupField() ? `${valueField}${option.const}` : option.const}
        value={currentValue}
        checked={currentValue === option.const}
        labels={{ label: option.title }}
      />
    );
  };

  return (
    <IconSettings iconPath="/assets/icons">
      {visible ? (
        isLookupField() ? (
          <>
            {getOptions().map((option) =>
              radioGroup(radioElement(option), option)
            )}
          </>
        ) : (
          <>{radioGroup(getOptions().map((option) => radioElement(option)))}</>
        )
      ) : (
        <></>
      )}
    </IconSettings>
  );
};

export default withJsonFormsControlProps(CQRadioControl);