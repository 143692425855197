import React from 'react';
import { withJsonFormsControlProps } from '@jsonforms/react';
import Input from '@salesforce/design-system-react/components/input';
import { isSalesforceBuild } from '../salesforceBuild';

const CQDateControl = ({data, handleChange, path, required,visible, label, enabled, uischema, errors}: any) => {

const handleDateChange = (evt : any) => {
  data = evt.target.value
  handleChange(path, data);
}

return (
    <>
    {visible?
    <Input
      type="date"   
      label={label}
      required={required}
      errorText={errors}  
      onInput={handleDateChange}
		  value={data}
      disabled={isSalesforceBuild() || !enabled}
    />:''}
    </>
  );
};

export default withJsonFormsControlProps(CQDateControl);