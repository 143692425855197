import strings from "localizations/formScreen";
export const FILE_SIZE = 100000000;


export interface fileUploaderprops {
    acceptedFiles: File[],
    fileRejections: any,
    setFileErrors: (arg0: any) => void,
    setUploading: (arg0: boolean) => void,
    addItem: (path: string, arg1: any) => Function,
    fileUploaderContext: any,
    path: string
}

// This Method takes the files uploaded by user and uploads it to salesforce files section
export const fileSelected = ({ acceptedFiles, fileRejections, setFileErrors, setUploading, addItem, fileUploaderContext, path }: fileUploaderprops) => {
    setFileErrors("");
    if (fileRejections) {
        fileRejections.forEach((file) => {
            file.errors.forEach((err) => {
                if (err.code === "file-too-large") {
                    const mb = FILE_SIZE / (1000000);
                    setFileErrors(strings.fileSizeError.replace('{0}', mb.toString()));
                }

                if (err.code === "file-invalid-type") {
                    setFileErrors(`Error: ${err.message}`);
                }
            });
        });
    }
    if (acceptedFiles) {
        acceptedFiles.forEach((file, index) => {
            // start upload if it fails fall back to upload
            try {
                setUploading(true);
                let fileInfo = fileUploaderContext.startFileUpload(file, path);
                if (fileInfo) {
                    addItem(path, {
                        ContentDocumentId: fileInfo.ContentDocumentId,
                        LocalId: fileInfo.LocalId,
                        Name: file.name,
                        error: fileInfo.error,
                        submissionId: fileInfo.submissionId
                    })();
                }
            } catch (ex) {
                console.log(ex);
                setFileErrors(`Error: ${ex.message}`);
            } finally {
                setUploading(false);
            }
        });
    }
}

// This Method updates uischema for files
export const getSchema = () => {
    return {
        "type": "VerticalLayout",
        "elements": [
            {
                "type": "filecard",
                "scope": "#"
            }
        ]
    };
}